<template>
  <div class="mb-10">
    <div class="flex">
      <div style="display: table">
        <div style="display: table-cell; vertical-align: middle">
          <img
            src="@/assets/CapturaAsistida/triangle.svg"
            style="width: 30px"
            alt=""
          />
        </div>
      </div>
      <div style="display: table; margin-left: 10px">
        <div style="display: table-cell; vertical-align: middle">
          <label class="sub-title">Información Económica</label>
        </div>
      </div>
    </div>
    <div class="md:ml-10">
      <div class="grid grid-cols-1 sm:gap-10">
        <div class="mt-10">
          <ChooseButton
            :buttons="buttonsEconomicInformation"
            v-on:choose-button="getValueChooseButton($event)"
            label="Periodo de ingreso:"
          />
          <div class="content-text" style="float: left">
            <template v-if="v$.salary_info_attributes.income_frecuency.$dirty">
              <div
                class="input-errors"
                v-for="error of v$.salary_info_attributes.income_frecuency
                  .$errors"
                :key="error.$uid"
              >
                <div class="errors">{{ error.$message }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10">
        <div class="mt-10">
          <p class="label-input w-full">Ingreso Bruto Mensual:</p>
          <input
            type="text"
            style="color: #3b3b3b"
            id="monthlygrossincome"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            v-money="money"
            @blur="validationIsgreaterthaninputs('monthlygrossincome')"
            onkeypress="if(this.value.length==10) return false;return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            :class="{
              'invalid-form':
                specialValidations.monthlygrossincome.valid,
              'valid-form': specialValidations.monthlygrossincome.contentErros,
            }"
          />
          <div class="content-text" style="float: left">
            <div v-if="specialValidations.monthlygrossincome.messages">
              <span
                class="errors"
                v-for="(errors, index) in specialValidations.monthlygrossincome
                  .messages"
                :key="errors"
                :id="'label_error_monthlygrossincome_' + index"
                >{{ errors }}</span
              >
            </div>
          </div>
        </div>

        <div class="mt-10">
          <p class="label-input w-full">Liquidez mensual:</p>
          <input
            type="text"
            style="color: #3b3b3b"
            id="monthlyliquidity"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            v-money="money"
            @blur="validationIsgreaterthaninputs('monthlyliquidity')"
            onkeypress="if(this.value.length==10) return false;return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            :class="{
              'invalid-form': specialValidations.monthlyliquidity.valid,
              'valid-form': specialValidations.monthlyliquidity.contentErros,
            }"
          />
          <div class="content-text" style="float: left">
            <div v-if="specialValidations.monthlyliquidity.messages">
              <span
                class="errors"
                v-for="(errors, index) in specialValidations.monthlyliquidity
                  .messages"
                :key="errors"
                :id="'label_error_monthlyliquidity_' + index"
                >{{ errors }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChooseButton from "@/components/Forms/Buttons/ChooseButton.vue";
import useVuelidate from "@vuelidate/core";
import { VMoney } from "v-money";
import Swal from "sweetalert2";
import { reactive, ref } from "vue-demi";
import { helpers } from '@vuelidate/validators';
import("@/assets/css/stylesheet.css");
import("@/assets/css/referredperson.css");
export default {
  name: "Economicinfomarmation",
  directives: { money: VMoney },
  
  setup() {
    
    let buttonsEconomicInformation = ref([
      { id: "button_semanal", value: "Semanal", text: "Semanal" },
      { id: "button_quincenal", value: "Quincenal", text: "Quincenal" },
      { id: "button_mensual", value: "Mensual", text: "Mensual" },
    ]);

    let money = ref({
      decimal: ".",
      thousands: ",",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false /* doesn't work with directive */,
    });
    let specialValidations = ref({
      monthlygrossincome: {
        valid: null,
        messages: [],
        contentErros: false,
      },
      monthlyliquidity: {
        valid: null,
        messages: [],
        contentErros: false,
      },
    });
    let salary_info_attributes = reactive({
      liquidity: "",
      monthly_income: "",
      income_frecuency: "",
    });


    function getValueChooseButton(event) {
      salary_info_attributes.income_frecuency = event; //Asignar el valor evitodo por el evento del componente hijo
    }
    function validationIsgreaterthaninputs(idinput) {
      if (idinput == "monthlygrossincome") {
        validationIsgreaterthan();
      } else {
        validationIsgreaterthan();
      }
    }
    function validationIsgreaterthan() {
      specialValidations.value.monthlygrossincome.messages = []; //Areglo de mensajes de errores
      specialValidations.value.monthlyliquidity.messages = []; //Areglo de mensajes de errores
      specialValidations.value.monthlygrossincome.valid = true;
      specialValidations.value.monthlyliquidity.valid = true;

      let monthlygrossincome = parseFloat(
        fomartNumber(document.getElementById("monthlygrossincome").value)
      );
      let monthlyliquidity = parseFloat(
        fomartNumber(document.getElementById("monthlyliquidity").value)
      );

      if (monthlygrossincome == 0) {
        specialValidations.value.monthlygrossincome.valid = true;
        specialValidations.value.monthlygrossincome.contentErros = false;
        specialValidations.value.monthlygrossincome.messages.push(
          "El ingreso bruto tiene que ser mayor a $0"
        );
      }
      if (monthlygrossincome > 1) {
        specialValidations.value.monthlygrossincome.valid = true;
        specialValidations.value.monthlygrossincome.contentErros = true;
      }
      if (monthlyliquidity === 0) {
        specialValidations.value.monthlyliquidity.valid = true;
        specialValidations.value.monthlyliquidity.contentErros = false;
        specialValidations.value.monthlyliquidity.messages.push(
          "El campo liquidez mensual tiene que ser mayor a $0"
        );
      }
      if (monthlyliquidity > 1) {
        specialValidations.value.monthlyliquidity.valid = true;
        specialValidations.value.monthlyliquidity.contentErros = true;
      }
      if (monthlygrossincome < monthlyliquidity) {
        Swal.fire({
          title: "Aviso",
          text: "El ingreso bruto tiene que ser mayor a liquidez mensual",
          icon: "warning",
          confirmButtonColor: "#FEB72B",
        });
        //AQUI
        monthlygrossincome = monthlygrossincome.value;
        monthlyliquidity = monthlyliquidity.value;

        specialValidations.value.monthlygrossincome.valid = true;
        specialValidations.value.monthlygrossincome.contentErros = false;
        specialValidations.value.monthlygrossincome.messages.push(
          "El ingreso bruto tiene que ser mayor a liquidez mensual"
        );
      } else {
        salary_info_attributes.monthly_income = monthlygrossincome;
        salary_info_attributes.liquidity = monthlyliquidity;
      }
    }
    function fomartNumber(value) {
      var regex = /^[,.]+$/;
      if (regex.test(value) != true) {
        value = value.replace(/[^0-9.]+/, "");
      }
      return value;
    }
    function validations() {
      this.v$.$touch();
      this.validationIsgreaterthan();
      return [
        specialValidations.value.monthlygrossincome.contentErros,
        specialValidations.value.monthlyliquidity.contentErros,
        !this.v$.$error,
      ];
    }

    let validation = reactive({
      salary_info_attributes: {
        income_frecuency: {
          required:helpers.withMessage("El campo periodo de ingreso es requerido",
         function(){
           return salary_info_attributes.income_frecuency=="" ? false:  true}
          ),
        },
      },
    });

    let v$ = useVuelidate(validation, salary_info_attributes);

    return {
      v$,
      getValueChooseButton,
      validationIsgreaterthaninputs,
      validationIsgreaterthan,
      fomartNumber,
      validations,
      buttonsEconomicInformation,
      money,
      specialValidations,
      salary_info_attributes,
    };
  },
  components: {
    ChooseButton,
  },
  watch:{
    salary_info_attributes:{
      handler(data){
        this.$emit("salary_info_attributes",data)
      },
      deep:true
    }
  },
  methods: {
    getValueChooseButton(event) {
      this.salary_info_attributes.income_frecuency = event; //Asignar el valor evitodo por el evento del componente hijo
    },
    validationIsgreaterthaninputs(idinput) {
      if (idinput == "monthlygrossincome") {
        this.validationIsgreaterthan();
      } else {
        this.validationIsgreaterthan();
      }
    },
    validationIsgreaterthan(){
      this.specialValidations.monthlygrossincome.messages=[]; //Areglo de mensajes de errores
      this.specialValidations.monthlyliquidity.messages=[];  //Areglo de mensajes de errores
      this.specialValidations.monthlygrossincome.valid=true;
      this.specialValidations.monthlyliquidity.valid=true;

      let monthlygrossincome=parseFloat(this.fomartNumber( document.getElementById("monthlygrossincome").value));
      let monthlyliquidity=parseFloat(this.fomartNumber(document.getElementById("monthlyliquidity").value));

        if(monthlygrossincome==0){
          this.specialValidations.monthlygrossincome.valid=true;
          this.specialValidations.monthlygrossincome.contentErros=false;
          this.specialValidations.monthlygrossincome.messages.push("El ingreso bruto tiene que ser mayor a $0");
        }
        if(monthlygrossincome>1){
          this.specialValidations.monthlygrossincome.valid=true;
          this.specialValidations.monthlygrossincome.contentErros=true;
        }
        if(monthlyliquidity===0){
          this.specialValidations.monthlyliquidity.valid=true;
          this.specialValidations.monthlyliquidity.contentErros=false;
          this.specialValidations.monthlyliquidity.messages.push("El campo liquidez mensual tiene que ser mayor a $0");
        }
        if(monthlyliquidity>1){
          this.specialValidations.monthlyliquidity.valid=true;
          this.specialValidations.monthlyliquidity.contentErros=true;
        }
        if (monthlygrossincome<monthlyliquidity) {
          Swal.fire({
            title: "Aviso",
            text: "El ingreso bruto tiene que ser mayor a liquidez mensual",
            icon: "warning",
            confirmButtonColor: "#FEB72B",
          });
          //AQUI
          this.monthlygrossincome=monthlygrossincome;
          this.monthlyliquidity=monthlyliquidity;

          this.specialValidations.monthlygrossincome.valid=true;
          this.specialValidations.monthlygrossincome.contentErros=false;
          this.specialValidations.monthlygrossincome.messages.push("El ingreso bruto tiene que ser mayor a liquidez mensual");
        }
        else{
          this.salary_info_attributes.monthly_income=monthlygrossincome;
          this.salary_info_attributes.liquidity=monthlyliquidity;
        }
    },
    fomartNumber(value){
      var regex = /^[,.]+$/;
      if (regex.test(value) != true) {
          value = value.replace(
            /[^0-9.]+/,
            ""
          );
        }
      return value;
    },
    validations(){
      this.$v.$touch();
      this.validationIsgreaterthan();
      return [this.specialValidations.monthlygrossincome.contentErros,this.specialValidations.monthlyliquidity.contentErros,!this.$v.$error]
    },
  },
};
</script>
