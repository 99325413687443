<template>

<div>
    <div class="flex mt-10">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <img src="@/assets/CapturaAsistida/triangle.svg" style="width:30px;" alt="">
        </div>
      </div>
      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title">Beneficiarios</label>
        </div>
      </div>
    </div>

    <!-- Listado de veneficiarios -->
    <div class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1 mt-10" v-if="rulesLoaded">
        <div class="row" style="margin-top:0px;margin-right:10px">
          <div class="center">
            <div class="justify-center md:ml-6">
              <div style="">
                <table class="width200">
                  <thead>
                    <tr>
                      <th class="text-xs p-10 md:w-40">Parentesco</th>
                      <th class="text-xs p-10">Apellido Paterno</th>
                      <th class="text-xs p-10">Apellido Materno</th>
                      <th class="text-xs p-10">Nombre</th>
                      <th class="text-xs p-10">Segundo nombre</th>
                      <th class="text-xs p-10 md:w-56">Fec. de Nac</th>
                      <th class="text-xs p-10">Porcentaje</th>
                      <th class="text-xs p-10">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(input, index) in listBeneficiaries.beneficiaries" :key=index>
                      <td>
                        <select @blur="v$.$touch" v-model="input.relationship" class="w-full px-2 py-2 focus:shadow-outline focus:outline-none text-center focus:border-cherryColor border-2 rounded-lg"
                           :id="'select_relationship_beneficary'+index"   :class="{'invalid-form':v$.beneficiaries.$each.$response.$data[index].relationship.$invalid && v$.$dirty}">
                          <option selected disabled >Seleccionar</option>
                          <option v-for="(relationship, index) in relationshipList" :key="relationship.name" :id="`select_relationship_${relationship.name.toString().toLowerCase()}_${index}`" :value="relationship.name.toString().toUpperCase()" >{{ relationship.name.toString().toUpperCase() }}</option>
                        </select> 
                        
                        <div v-show="v$.$dirty" class="content-text" style="float:left">
                          <div class="input-errors" v-for="error of v$.beneficiaries.$each.$response.$errors[index].relationship" :key="error.$uid">
                            <span class="errors">{{ error.$message }}</span>
                          </div>
                        </div>
                        
                      </td>

                      <td>
                        <input @blur="v$.$touch" type="text" v-model="input.paternal_name" class="w-full px-2 py-2 focus:shadow-outline focus:outline-none text-center focus:border-cherryColor border-2 rounded-lg" 
                           :id="'input_paternal_name_beneficiary'+index" :class="{
                          'invalid-form': v$.beneficiaries.$each.$response.$data[index].paternal_name.$invalid && v$.$dirty}">
                          
                          <div v-show="v$.$dirty" class="content-text" style="float:left">
                            <div class="input-errors" v-for="error of v$.beneficiaries.$each.$response.$errors[index].paternal_name" :key="error.$uid">
                              <p class="errors">{{ error.$message }}</p>
                            </div>
                          </div>
                 
                      </td>
                      
                      <td>
                        <input @blur="v$.$touch" type="text" v-model="input.maternal_name" class="w-full px-2 py-2 focus:shadow-outline focus:outline-none text-center focus:border-cherryColor border-2 rounded-lg" 
                           :id="'input_maternal_name_beneficiary'+index" :class="{
                          'invalid-form': v$.beneficiaries.$each.$response.$data[index].maternal_name.$invalid && v$.$dirty
                        }">
                        
                        <div v-show="v$.$dirty" class="content-text" style="float:left">
                            <div class="input-errors" v-for="error of v$.beneficiaries.$each.$response.$errors[index].maternal_name" :key="error.$uid">
                              <span class="errors">{{ error.$message }}</span>
                           </div>
                        </div>

                      </td>

                      <td>
                        <input @blur="v$.$touch" type="text" v-model="input.name" class="w-full px-2 py-2 focus:shadow-outline focus:outline-none text-center focus:border-cherryColor border-2 rounded-lg" 
                           :id="'input_name_beneficiary'+index" :class="{
                          'invalid-form': v$.beneficiaries.$each.$response.$data[index].name.$invalid && v$.$dirty
                        }">

                         <div v-show="v$.$dirty" class="content-text" style="float:left">
                            <div class="input-errors" v-for="error of v$.beneficiaries.$each.$response.$errors[index].name" :key="error.$uid">
                              <span class="errors">{{ error.$message }}</span>
                           </div>
                        </div>
                        
                      </td>
                      
                      <td>
                        <input @blur="v$.$touch" type="text" v-model="input.middle_name" class="w-full px-2 py-2 focus:shadow-outline focus:outline-none text-center focus:border-cherryColor border-2 rounded-lg" 
                           :id="'input_middle_name_beneficiary'+index" :class="{
                          'invalid-form': v$.beneficiaries.$each.$response.$data[index].middle_name.$invalid && v$.$dirty
                        }">

                         <div v-show="v$.$dirty" class="content-text" style="float:left">
                            <div class="input-errors" v-for="error of v$.beneficiaries.$each.$response.$errors[index].middle_name" :key="error.$uid">
                              <span class="errors">{{ error.$message }}</span>
                           </div>
                        </div>
                        
                      </td>

                      <td>
                        <Dates
                          @blur="v$.$touch"
                          :key="input.yearBirth"
                          v-on:get-complete-date-selected="getResponseDate($event,index)"
                          :year="input.yearBirth"
                          :month="input.monthBirth"
                          :day="input.dayBirth"
                          :idYear="'select_birthdate_year_beneficiaries_info_'+index"
                          :idMonth="'select_birthdate_month_beneficiaries_info_'+index"
                          :idDay="'select_birthdate_day_beneficiaries_info_'+index"
                          />

                          <div v-show="v$.$dirty" class="content-text" style="float:left;margin-top:10px">
                              <div class="input-errors" v-for="error of v$.beneficiaries.$each.$response.$errors[index].birthday" :key="error.$uid">
                                <span class="errors">{{ error.$message }}</span>
                              </div>
                          </div>

                      </td>

                      <td>
                        <input @blur="v$.$touch" type="number" v-model="input.percentage"  class="w-full px-2 py-2 focus:shadow-outline focus:outline-none text-center focus:border-cherryColor border-2 rounded-lg" 
                           :id="'input_percentage_beneficiary'+index" :class="{
                          'invalid-form': v$.beneficiaries.$each.$response.$data[index].percentage.$invalid && v$.$dirty}">

                          <div v-show="v$.$dirty" class="content-text" style="float:left;margin-top:10px">
                            <div>
                              <div class="input-errors" v-for="error of v$.beneficiaries.$each.$response.$errors[index].percentage" :key="error.$uid">
                                <span class="errors">{{ error.$message }}</span>
                              </div>
                            </div>
                          </div>
                      </td>

                      <td style="color: black">
                        <button v-on:click="removeBeneficiarie(index)" :id="'Delete_beneficiaries'+index" style="text-decoration: none;margin-left:5px;"><img height="35" width="35" src="@/assets/Cuenta/delete-icon.svg"></button>
                      </td>


                    </tr>
                    <tr class="visibility-columns"><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                  </tbody>
                    <tr class="text-sm">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>Total:</th>
                      <th><div>{{totalallocation}} %</div>
                      <div v-if="totalallocation>100">
                        <span class="errors"  :id="'label_percentage_more_one_hundred'" >El porcentaje total de los beneficiarios debe ser de 100%, para continuar con el proceso de registro.</span>  
                      </div>
                      <div v-if="totalallocation<100 && totalallocation>0">
                        <span class="errors"  :id="'label_percentage_smaller_than_one_hundred'" >El porcentaje total de los beneficiarios debe ser de 100%, para continuar con el proceso de registro.</span>  
                      </div>
                      </th>
                      <th></th>
                    </tr>
                </table>
                <div>
                    <button style="float:right" class="focus:shadow-outline focus:outline-none" id="Add_beneficiaries"  v-on:click="addNewBeneficiary()">
                       <div class="flex">
                        <div class="mt-5">
                          Agregar beneficiario
                        </div>
                      <div><img src="@/assets/more.svg" alt="" srcset=""   width="50" height="50"></div> 
                      </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div>
    </div>
  </div>    
</div>
    
</template>

<script>
import('@/assets/css/stylesheet.css');
import useVuelidate from "@vuelidate/core";
import Dates from '@/components/Forms/Selects/Date';
import { minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
import Swal from 'sweetalert2';
import $ from "jquery";
import { helpers } from '@vuelidate/validators';
import { reactive, ref, onBeforeMount } from 'vue-demi';
import moment from "moment";
import User from '@/classes/User.js';

export default {
    name:"Beneficiaries",
    components:{
      Dates
    },
    props: {
      rulesValidations: {
        type: Object,
        default: () => {}
      },
      rulesLoaded: {
        type: Boolean,
        default: false
      },
    },
    watch:{
      listBeneficiaries:{
        handler(data){
          var tablePosition = null;
          var isSpouse = data.beneficiaries.filter((value, i) => { 
            if(value.relationship == "CONYUGE"){
              tablePosition = i;
              return value;
            };
          });
          
          if(isSpouse.length == 1){
            var conyugeInformattion = (this.conyuge.name != "")? true : false;
            if(conyugeInformattion){
              this.listBeneficiaries.beneficiaries[tablePosition].paternal_name = this.conyuge.first_name;
              this.listBeneficiaries.beneficiaries[tablePosition].maternal_name = this.conyuge.last_name;
              this.listBeneficiaries.beneficiaries[tablePosition].name = this.conyuge.name;
              this.listBeneficiaries.beneficiaries[tablePosition].middle_name = this.conyuge.middle_name;
              this.listBeneficiaries.beneficiaries[tablePosition].status = 'activo';

              if(this.conyuge.year_birth && this.conyuge.month_birth && this.conyuge.day_birth){
                this.listBeneficiaries.beneficiaries[tablePosition].birthday = `${this.conyuge.year_birth}-${this.conyuge.month_birth}-${this.conyuge.day_birth}`;
                this.listBeneficiaries.beneficiaries[tablePosition].yearBirth = this.conyuge.year_birth;
                this.listBeneficiaries.beneficiaries[tablePosition].yearBirth = this.conyuge.year_birth;
                this.listBeneficiaries.beneficiaries[tablePosition].monthBirth = this.conyuge.month_birth;
                this.listBeneficiaries.beneficiaries[tablePosition].dayBirth = this.conyuge.day_birth;
              }
            }
          } 
          
          this.$emit("listBeneficiaries", null); 
          this.totalallocation = 0;
          let total = 0;

          data.beneficiaries.forEach(element => {
            if(element.percentage<0){
              element.percentage=0;
            }
            if(element.percentage!=""){
              total=total+parseInt(element.percentage);
            }
          });

          if(!isNaN(total)){
            this.totalallocation=total;
            if(total>100){
                Swal.fire({
                    title: "Aviso",
                    text: "El porcentaje entre todos los beneficiarios debe ser 100%.",
                    icon: "warning",
                    confirmButtonColor: '#FEB72B',
                  });    
            }
            if(total==100 && this.totalallocation<=100){
              this.$emit("listBeneficiaries",Object.assign({},data))
            }
            
          }
        },
        deep:true
      },
    },
    setup(props){
      var listMonths = ref(['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']);

      let listBeneficiaries=ref({
        beneficiaries:[{
          name:"",
          paternal_name:"",
          maternal_name:"",
          middle_name:"",
          relationship:"",
          birthday:"",
          percentage:"",
          yearBirth:"",
          monthBirth:"",
          dayBirth:"",
        }]
      });

      let conyuge = ref({
        name: '',
        middle_name: '',
        first_name: '',
        last_name: '',
        year_birth: '',
        day_birth: '',
        month_birth: ''
      });

      let totalallocation=ref(0);

      let birthday = ref("");

      let spouse = ref({
        gender: "",
        maternal_name: "",
        middle_name: "",
        name: "",
        paternal_name: "",
        rfc: ""
      });

      let relationshipList = ref([]);

      const userObjectClass = ref(null);

      onBeforeMount(async() => {
        var token = sessionStorage.getItem("login");

        userObjectClass.value = new User();
        getInfoConyuge();
        getListRelationship(token);
        // console.log(relationshipList.value);
      });

      let rulesValidations=reactive({
        beneficiaries:{
          $each:helpers.forEach({
            name:{
              required:helpers.withMessage("El campo nombre es requerido.",requiredIf(function(){return props.rulesValidations.beneficiatyes.name.required})),
              minLength: helpers.withMessage("El campo nombre debe contener mínimo "+props.rulesValidations.beneficiatyes.name.minLength+" caracteres.", minLength(props.rulesValidations.beneficiatyes.name.minLength)),
              maxLength: helpers.withMessage("El campo nombre debe contener máximo "+props.rulesValidations.beneficiatyes.name.maxLength+" caracteres.", maxLength(props.rulesValidations.beneficiatyes.name.maxLength)),
              pattern: helpers.withMessage("El campo nombre no acepta caracteres especiales.",function(value,index) {
                if(index.name != ""){
                  return new RegExp(/^[a-zA-ZÀ-ÿ\-\s]+$/).test(value);
                }else{ 
                  return true
                } 
              })
            },
            paternal_name:{
              required:helpers.withMessage("El apellido paterno es requerido.",function(data,index){
                if(index.maternal_name == ""){
                  return index.paternal_name!=""?true:false
                }else{
                  return true
                }
              }),
              minLength: helpers.withMessage("El campo apellido paterno debe contener mínimo "+props.rulesValidations.beneficiatyes.paternal_name.minLength+" caracteres.", minLength(props.rulesValidations.beneficiatyes.paternal_name.minLength)),
              maxLength: helpers.withMessage("El campo apellido paterno debe contener máximo "+props.rulesValidations.beneficiatyes.paternal_name.maxLength+" caracteres.", maxLength(props.rulesValidations.beneficiatyes.paternal_name.maxLength)),
              pattern: helpers.withMessage("El campo apellido paterno no acepta caracteres especiales.",function(value,index) {
                if(index.paternal_name != ""){
                  return new RegExp(/^[a-zA-ZÀ-ÿ\-\s]+$/).test(value);
                }else{ 
                  return true
                } 
              })
            },
            maternal_name:{
              required:helpers.withMessage("El apellido materno es requerido.",function(data,index){
                if(index.paternal_name == ""){
                  return index.maternal_name!=""?true:false
                }else{
                  return true
                }
              }),
              minLength: helpers.withMessage("El campo apellido materno debe contener mínimo "+props.rulesValidations.beneficiatyes.maternal_name.minLength+" caracteres.", minLength(props.rulesValidations.beneficiatyes.maternal_name.minLength)),
              maxLength: helpers.withMessage("El campo apellido materno debe contener máximo "+props.rulesValidations.beneficiatyes.maternal_name.maxLength+" caracteres.", maxLength(props.rulesValidations.beneficiatyes.maternal_name.maxLength)),
              pattern: helpers.withMessage("El campo apellino materno no acepta caracteres especiales.",function(value,index) {
                if(index.maternal_name != ""){
                  return new RegExp(/^[a-zA-ZÀ-ÿ\-\s]+$/).test(value);
                }else{ 
                  return true
                } 
              })
            },
            middle_name:{
              minLength:helpers.withMessage("El campo segundo nombre tiene que tener mínimo "+props.rulesValidations.beneficiatyes.middle_name.minLength+" caracteres." ,minLength(props.rulesValidations.beneficiatyes.middle_name.minLength)),
              maxLength: helpers.withMessage("El campo segundo nombre tiene que tener máximo "+props.rulesValidations.beneficiatyes.middle_name.maxLength+" caracteres.", maxLength(props.rulesValidations.beneficiatyes.middle_name.maxLength)),
              pattern: helpers.withMessage("El campo segundo nombre no acepta caracteres especiales.",function(value,index) {
                if(index.middle_name != ""){
                  return new RegExp(/^[a-zA-ZÀ-ÿ\-\s]+$/).test(value);
                }else{ 
                  return true
                } 
              })
            },
            relationship:{
               required:helpers.withMessage("El campo parentesto es requerido.",requiredIf(function(){return props.rulesValidations.beneficiatyes.relationship.required}))
            },
            birthday:{
               minValue:helpers.withMessage("El campo fecha de nacimiento es invalido.",function (data,index){
                 
                 if((((new Date(data))<= (new Date(birthday.value))) && index.relationship=="HIJO")){
                  Swal.fire({
                    title: "Aviso",
                    text: "La fecha de nacimiento del beneficiario hijo no puede ser mayor a la del titular/socio.",
                    icon: "warning",
                    confirmButtonColor: '#FEB72B',
                  });       
                  return false;
                }else{
                  if(((new Date(data))>= (new Date(birthday.value))) && (index.relationship=="PADRE" || index.relationship=="MADRE")){
                    Swal.fire({
                      title: "Aviso",
                      text: "La fecha de nacimiento del padre/madre no debe ser menor a la del titular/socio",
                      icon: "warning",
                      confirmButtonColor: '#FEB72B',
                      });       
                    return false;
                  }else{
                    if( ((calculateAge(index.birthday))!=true) && index.relationship=="CONYUGE" && index.birthday!=""){
                        Swal.fire({
                          title: "Aviso",
                          text: "El Cónyuge debe ser mayor de edad",
                          icon: "warning",
                          confirmButtonColor: '#FEB72B',
                        });       
                    return false;
                   }else {
                      if(((new Date(data))>= (new Date(birthday.value))) && (index.relationship=="ABUELO")){
                        Swal.fire({
                          title: "Aviso",
                          text: "La fecha de nacimiento del Abuelo no debe ser menor a la del titular/socio",
                          icon: "warning",
                          confirmButtonColor: '#FEB72B',
                        });       
                    return false;
                    } else { 
                      return true; 
                    }
                   }
                  }
                }
              }),
              required:helpers.withMessage("El campo fecha de nacimiento es requerido.",function(data,index){
                if(index.birthday == ""){
                  return false
                }else{
                  return true
                }
              })
            },
            percentage:{
              required:helpers.withMessage("El campo porcentaje es requerido.",requiredIf(function(){return props.rulesValidations.beneficiatyes.percentage.required}))
            }
          })
        }
      });

      const v$=useVuelidate(rulesValidations,listBeneficiaries);

      function touch(){
        this.v$.$validate()
        return (this.v$.$error || totalallocation.value < 100)
      }

      function getResponseDate(event,index){
        listBeneficiaries.value.beneficiaries[index].birthday = event;
      }

      function calculateAge(birthday) {
        if(birthday != ""){
          let date = birthday.split('-');
          if(isNaN(date[1])){
            let monthNumber = listMonths.value.findIndex( month => month === date[1]);
            monthNumber = (monthNumber<10)? `0${monthNumber+1}` : monthNumber+1;
            birthday = `${date[0]}-${ monthNumber }-${date[2]}`;
          }
        }
        
        var today = new Date();
        var birthDate = new Date(birthday);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
            age--;
        }
        return age>=props.rulesValidations.beneficiatyes.legal_age.age
      }

      function removeBeneficiarie(index){
       Swal.fire({
          title: "Aviso",
          text: "¿Desea eliminar el beneficiario?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "SI",
          cancelButtonText: "NO",
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B'
        }).then((result) => {
          if (result.value) {
              listBeneficiaries.value.beneficiaries.splice(index,1);
            }
          });
        setIdAttributeToAccepAndCancelButtonSwalFire('button_confirm_delete', 'button_cancel_delete') 
      }
      function  setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
        $('.swal2-confirm').attr('id', idButtonConfirm);
        $('.swal2-cancel').attr('id', idButtonCancel);
      }

      function  addNewBeneficiary(){
        this.v$.$validate();
        if(totalallocation.value<100 ){
          if(this.v$.$error==false){
             let beneficiareModel={
                name: "",
                gender:"",
                status:"activo",
                birthday:"",
                percentage:"",
                middle_name:"",
                relationship:"",
                maternal_name:"",
                paternal_name:""
              };
            listBeneficiaries.value.beneficiaries.push(beneficiareModel)
          }else{
            Swal.fire({
              title: "Aviso",
              text: 'Se deben llenar los campos con el formato correcto en la tabla.',
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
          }
        }else{
           Swal.fire({
            title: "Aviso",
            text: 'Ya se cuenta con el 100% asignado.',
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
        }
      }

      function changePercentage(value) {
        if (value.includes(".")) {
          value = parseFloat(value);
        }
        var value = parseInt(value);
        if (value < 1 || value > 100) {
          Swal.fire({
            title: "Aviso",
            text: "El porcentaje debe estar entre 1 y 100",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          value = 1;
        }
        return value;
      }

      function getBirthdayByRfc(value){
        var m = value.match( /^\w{4}(\w{2})(\w{2})(\w{2})/ );
        var year = parseInt(m[1],10)+1900;
        if( year < 1925 ) year += 100;
        var month = parseInt(m[2], 10)-1;
        var day = parseInt(m[3], 10);
        return (new Date( year, month, day ));
      }

      async function getListRelationship(token){
        await userObjectClass.value.getListRelationship(token).then(resp => {
          const data = resp.data.response.list_catalog_kinship.filter(kinship => kinship.status == 'activo');
          relationshipList.value = data? data: [];
          // console.log(relationshipList.value);
        }).catch(err => {
          console.log("Error:", err);
        });
        
      }

      function getInfoConyuge(){
        var admissionRequest = JSON.parse(sessionStorage.getItem('admissionRequest'));
        if(admissionRequest){
          birthday.value = admissionRequest.birthday;
          spouse.value = admissionRequest.spouse_attributes;
          if(spouse.value.rfc){
                let spouseBirthday = (spouse.value.rfc)? getBirthdayByRfc(spouse.value.rfc): '';
                conyuge.value.first_name = spouse.value.paternal_name;
                conyuge.value.last_name = spouse.value.maternal_name;
                conyuge.value.name = spouse.value.name;
                conyuge.value.middle_name = spouse.value.middle_name;

                if(spouseBirthday){
                  conyuge.value.year_birth = moment(spouseBirthday).format('YYYY');
                  conyuge.value.month_birth = spouseBirthday.getMonth();
                  conyuge.value.month_birth = listMonths.value[parseInt(conyuge.value.month_birth)];
                  conyuge.value.day_birth = moment(spouseBirthday).format('DD').toString();
                }
          }
        }
        
      }

      return {
        v$,
        listBeneficiaries,
        relationshipList,
        rulesValidations,
        touch,
        getResponseDate,
        removeBeneficiarie,
        addNewBeneficiary,
        changePercentage,
        totalallocation,
        spouse,
        getBirthdayByRfc,
        listMonths,
        getInfoConyuge,
        conyuge
      }
    }
  }

</script>

<style scoped>
.visibility-columns{
  display: none!important;
}
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even){background-color: #ffffff}

th {
  background-color: #ffffff;

}

table.width200,table.rwd_auto {border:1px solid #ccc;width:100%;margin:0 0 50px 0}
    .width200 th,.rwd_auto th {background:#ffffff;text-align:center;border:1px solid #ccc;}
    .width200 td,.rwd_auto td {border:1px solid #ccc;text-align:center}
    .width200 tr:last-child td, .rwd_auto tr:last-child td{border:0}

/*
.rwd {width:100%;overflow:auto;}
    .rwd table.rwd_auto {width:auto;min-width:100%}
        .rwd_auto th,.rwd_auto td {white-space: nowrap;} */

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}
@media only screen and (max-width: 1365px)
{
    .visibility-columns {display: none!important;}
  
    table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }

    .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }

    .width200 tr th:nth-child(5){display: none;}

    .width200 tr { border: 1px solid #ccc;}

    .width200 td { border: none;border-bottom: 1px solid #ccc; position: relative;padding-left: 40%;text-align:left }

    .width200 td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap; font-size: 10px}

    .width200 td:nth-of-type(1):before { content: "Parentesco:"; }
    .width200 td:nth-of-type(2):before { content: "Apellido Paterno:"; }
    .width200 td:nth-of-type(3):before { content: "Apellido Materno:"; }
    .width200 td:nth-of-type(4):before { content: "Nombre:"; }
    .width200 td:nth-of-type(5):before { content: "Segundo nombre:"; }
    .width200 td:nth-of-type(6):before { content: "Fec. de Nac:"; }
    .width200 td:nth-of-type(7):before { content: "Porcentaje:"; }
    .width200 td:nth-of-type(8):before { content: "Acciones:"; }
    .width200 th:nth-of-type(9):before { content: "TOTAL: "; }


    .descarto {display:none;}
    .fontsize {font-size:10px}

    
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) and (max-width : 480px)
{
    .descarto {display:none;}
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px)
{
    .descarto {display:none;}
    .fontsize {font-size:10px}
}
</style>