<template>
  <div style="overflow-x: hidden;">
   <Loading :isLoading="isLoading"/>
    <LaborInformation 
      ref="laborInformationRefs"
      :rulesValidations="rulesValidations"
      :rulesLoaded="rulesLoaded"
      v-on:labor-information-data="getLaborInformationData($event)"
      v-if="rulesLoaded"
    />
    <Economici
    ref="economiciRefs"
    v-on:salary_info_attributes="getEconomici($event)"/>
    <SavingData 
    ref="savingdataRefs"
    :rulesValidations="rulesValidations"
    :rulesLoaded="rulesLoaded"
    v-on:saving_registration_attributes="getSavingRegistrationAttributes($event)"
    v-on:bank_account_attributes="getBankAccountAttributes($event)"/>
   <Beneficiaries
    v-if="rulesLoaded"
    ref="beneficiariesRefs"
    :rulesValidations="rulesValidations" 
    :rulesLoaded="rulesLoaded"
    v-on:listBeneficiaries="getBeneficiaries($event)" />
   <UploadFiles 
      ref="uploadFileRefs"
      class="mt-10" 
      v-on:upload-files-data="getUploadFilesData($event)" />
    <div class="flex mt-20">
      <input type="button" class="cursor-pointer shadow-md rounded-lg w-full md:w-1/4 mx-auto px-3 py-3 mt-2 btn-next" @click="finishCapture()" value="Finalizar Captura">
    </div>
    <button></button>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeMount } from 'vue';
import LaborInformation from '@/components/Forms/AssistedEnrollment/LaborInformation';
import SavingData from "@/components/Forms/AssistedEnrollment/SavingData.vue"
import Economici from "@/components/Forms/AssistedEnrollment/EconomicInformation";
import UploadFiles from '@/components/Forms/AssistedEnrollment/step2/UploadFiles'
import Beneficiaries from "@/components/Forms/AssistedEnrollment/Beneficiaries"
import Loading from '@/components/Loading/VueLoading.vue'

import validations from '@/helpers/validations';

import AdmissionRequest from '@/classes/AdmissionRequest';
import Document from '@/classes/Document';

import Swal from "sweetalert2";
import SwalAlertHelper from '@/helpers/swal-alert'
import {useRouter} from "vue-router";

import moment from "moment";
import { getTokenDecoden } from '@/helpers/tokenauth.js';
import { getMonths } from '@/helpers/dates.js';

export default {
  name: 'AssistedEnrollmentStep2Component',
  setup() {
    let multiApi = process.env.VUE_APP_URLWEB;
    let rulesValidations = ref();
    let rulesLoaded = ref(false)
    let admissionRequestObject = ref({});
    let documentObjectClass = ref(null);
    let atachedDocuments = ref([]);
    let documentsUploaded = ref("");
    let isLoading = ref(false);
    let workCenter = ref("");
    let accountType = ref();

    const economiciRefs = ref(null);
    const savingdataRefs = ref(null);
    const laborInformationRefs = ref(null);
    const beneficiariesRefs = ref(null);
    const uploadFileRefs = ref(null);

    const router = useRouter();

    onMounted(()=>{
      // accountType.value=JSON.parse(sessionStorage.getItem("login")).tipo_cuenta;
      const _sessionStorage=getTokenDecoden();
      accountType.value=_sessionStorage.obj['role'];
      if(!sessionStorage.getItem("admissionRequest")){
        router.push({ path:`/captura-asistida/${accountType.value}` });
      }
      documentObjectClass.value = new Document();
      admissionRequestObject = new AdmissionRequest();
      // console.log("🚀 ~ file: AssistedEnrollmentStep2.vue:90 ~ onMounted ~ admissionRequestObject", admissionRequestObject)
      admissionRequestObject.data = JSON.parse(sessionStorage.getItem("admissionRequest"));
    });

    onBeforeMount(()=>{
      getValidationsRules();
    });

    async function getValidationsRules() {
      const response = await validations.getStructure();
      rulesValidations.value = response.data.validations;
      rulesLoaded.value = true;
    }

    function getLaborInformationData(data) {
      // Atributos del lugar de trabajo (Work)
      admissionRequestObject.data.work_info_attributes.supervisor = "-----";//this.supervisor == "" ? "-----" : this.supervisor.trim();
      admissionRequestObject.data.work_info_attributes.employee_number = "-----";//[addUpperCase(this.presupustalCode).trim()];
      admissionRequestObject.data.work_info_attributes.category = "-----";//this.category == "" ? "-----" : addUpperCase(this.category);
      admissionRequestObject.data.work_info_attributes.active_since = moment().format('YYYY-MM-DD');
      admissionRequestObject.data.work_info_attributes.status = (data.work.workStatus)? 'activo':'inactivo';
      admissionRequestObject.data.work_info_attributes.laboral_status = (data.work.workStatus == true)?'ACTIVO': (data.work.workStatus==null)? 'ESTUDIANTE':'JUBILADO';
      admissionRequestObject.data.work_info_attributes.occupation = data.work.workCenter.businessLine == "" ? "-----" : data.work.workCenter.businessLine;

      admissionRequestObject.data.workplace_attributes.center_key = "-----";
      admissionRequestObject.data.workplace_attributes.employer = (data.work.workCenter.unionSection == 'other')? "-----": data.work.workCenter.unionSection;
      admissionRequestObject.data.work_info_attributes.business_name = data.work.workName == "" ? "-----" : data.work.workName;
      
      admissionRequestObject.data.workplace_attributes.name = data.work.workCenter.workCenter == "" ? "-----" : addUpperCase(data.work.workCenter.workCenter);
      admissionRequestObject.data.workplace_attributes.between_street_one = "";
      admissionRequestObject.data.workplace_attributes.between_street_two = "";
      
      admissionRequestObject.data.work_info_attributes.principal_occupation = data.work.typeOfWork;
      let dateWork={
        laboral_status:admissionRequestObject.data.work_info_attributes.laboral_status, //Activo o jubilado
        principal_occupation:admissionRequestObject.data.work_info_attributes.principal_occupation,
        workCenter: data.work.workCenter.workCenter == "" ? "-----" : addUpperCase(data.work.workCenter.workCenter)
      };

      savingdataRefs.value.statusEmplyment(dateWork);
      if(data.work.workStatus && admissionRequestObject.data.work_info_attributes.principal_occupation == "Trabajador de la educacion"){
        admissionRequestObject.data.client_classification = "educator"
      }
      if(data.work.workStatus && admissionRequestObject.data.work_info_attributes.principal_occupation == "Negocio Propio"){
        admissionRequestObject.data.client_classification = "businessman"
      }
      if(data.work.workStatus && admissionRequestObject.data.work_info_attributes.principal_occupation == "Empleado" && admissionRequestObject.data.workplace_attributes.name == "OTRO"){
        admissionRequestObject.data.client_classification = ""
      }
      if(data.work.workStatus && admissionRequestObject.data.work_info_attributes.principal_occupation == "Empleado" && admissionRequestObject.data.workplace_attributes.name == "GRUPO CABSA"){
        admissionRequestObject.data.client_classification = "cabsa_employee"
      }

      if(data.work.workStatus == false){
        admissionRequestObject.data.client_classification = "retired";
      }

      if(admissionRequestObject.data.work_info_attributes.laboral_status == "ESTUDIANTE"){
        admissionRequestObject.data.client_classification = "student"
        admissionRequestObject.data.work_info_attributes.principal_occupation = "student"
      }

      if(admissionRequestObject.data.work_info_attributes.occupation == "Salud pública"){
        admissionRequestObject.data.client_classification = "";
      }

      // Atributos del lugar de trabajo (Address)
      admissionRequestObject.data.workplace_attributes.name = (data.work.freeWorkCenterName == "") ? "-----" : addUpperCase(data.work.freeWorkCenterName);
      admissionRequestObject.data.workplace_attributes.postal_code = (data.address.postalCode == undefined || data.address.postalCode.toString() == "") ? "85000" : data.address.postalCode.toString();
      admissionRequestObject.data.workplace_attributes.city = (data.address.location.city.nombre == "" || data.address.location.city.nombre == undefined)? "-----" : addUpperCase(data.address.location.city.nombre);
      admissionRequestObject.data.workplace_attributes.state = (data.address.location.state.nombre == "" || data.address.location.state.nombre == undefined) ? "-----" : addUpperCase(data.address.location.state.nombre);
      admissionRequestObject.data.workplace_attributes.country = 'México'.toUpperCase();
      admissionRequestObject.data.workplace_attributes.status = 'activo';
      admissionRequestObject.data.workplace_attributes.municipality = (data.address.location.municipality.nombre == "" || data.address.location.municipality.nombre == undefined)? "-----" : addUpperCase(data.address.location.municipality.nombre);
      admissionRequestObject.data.workplace_attributes.suburb = data.address.suburb == "" ? "-----" : addUpperCase(data.address.suburb);
      admissionRequestObject.data.workplace_attributes.street = (data.address.street == undefined || data.address.street == "") ? "-----" : addUpperCase(data.address.street).toString().trim();
      admissionRequestObject.data.workplace_attributes.ext_number = data.address.exteriorNumber == "" ? "-----" : data.address.exteriorNumber;
      admissionRequestObject.data.workplace_attributes.int_number = "";

      admissionRequestObject.data.workplace_attributes.adress_attributes.postal_code = (data.address.postalCode == undefined || data.address.postalCode.toString() == "") ? "85000" : data.address.postalCode.toString();
      admissionRequestObject.data.workplace_attributes.adress_attributes.city = (data.address.location.city.nombre == "" || data.address.location.city.nombre == undefined) ? "-----" : addUpperCase(data.address.location.city.nombre);
      admissionRequestObject.data.workplace_attributes.adress_attributes.state = (data.address.location.state.nombre == "" || data.address.location.state.nombre == undefined) ? "-----" : addUpperCase(data.address.location.state.nombre);
      admissionRequestObject.data.workplace_attributes.adress_attributes.country = 'MÉXICO';
      admissionRequestObject.data.workplace_attributes.adress_attributes.status = 'activo';
      admissionRequestObject.data.workplace_attributes.adress_attributes.municipality = (data.address.location.municipality.nombre == "" || data.address.location.municipality.nombre == undefined) ? "-----" : addUpperCase(data.address.location.municipality.nombre);
      admissionRequestObject.data.workplace_attributes.adress_attributes.suburb = data.address.suburb == "" ? "-----" : addUpperCase(data.address.suburb );
      admissionRequestObject.data.workplace_attributes.adress_attributes.street = (data.address.street == undefined || data.address.street == "") ? "-----" : addUpperCase(data.address.street).toString().trim();
      admissionRequestObject.data.workplace_attributes.adress_attributes.ext_number = data.address.exteriorNumber == "" ? "-----" : data.address.exteriorNumber;
      admissionRequestObject.data.workplace_attributes.adress_attributes.between_street_one = "";
      admissionRequestObject.data.workplace_attributes.adress_attributes.between_street_two = "";
      
      // Atributos de contacto (Telephone)
      admissionRequestObject.data.workplace_attributes.contact_info_attributes[0].value =  removeMask(data.telephone.number);
      admissionRequestObject.data.workplace_attributes.contact_info_attributes[0].extension = data.telephone.lada;
      admissionRequestObject.data.workplace_attributes.contact_info_attributes[0].status = 'activo';

      // Atributos de cargo publico (Titular) 
      const {questionTwo:publicChargeQuestionTwo, questionOne:publicChargeQuestionOne, endDate: publicChargeQuestionEndDate, startDate: publicChargeQuestionStartDate} = data.publicCharge;

      admissionRequestObject.data.public_office_attributes[0].relationship = "titular";
      admissionRequestObject.data.public_office_attributes[0].positions_name = publicChargeQuestionTwo;
      admissionRequestObject.data.public_office_attributes[0].has_had_public_positions = publicChargeQuestionOne? true: false;
      admissionRequestObject.data.public_office_attributes[0].end_date_public_positions = publicChargeQuestionEndDate;
      admissionRequestObject.data.public_office_attributes[0].start_date_public_positions = publicChargeQuestionStartDate;
      
      // Atributos de cargo publico (Familiar)
      const {questionTwo:publicChargeFamilyQuestionTwo, questionOne:publicChargeFamilyQuestionOne, endDate: publicChargeFamilyQuestionEndDate, startDate: publicChargeFamilyQuestionStartDate} = data.publicChargeFamily;

      admissionRequestObject.data.public_office_attributes[1].relationship = "familiar";
      admissionRequestObject.data.public_office_attributes[1].positions_name = publicChargeFamilyQuestionTwo;
      admissionRequestObject.data.public_office_attributes[1].has_had_public_positions = publicChargeFamilyQuestionOne? true: false;
      admissionRequestObject.data.public_office_attributes[1].end_date_public_positions = publicChargeFamilyQuestionEndDate;
      admissionRequestObject.data.public_office_attributes[1].start_date_public_positions = publicChargeFamilyQuestionStartDate;
    }

    function getEconomici(economidata){
      admissionRequestObject.data.salary_info_attributes = economidata;
      admissionRequestObject.data.salary_info_attributes.income_frecuency = economidata.income_frecuency.toString().toLowerCase();
    }

    function getSavingRegistrationAttributes(data){
      admissionRequestObject.data.saving_registration_attributes.payment_frecuency = data.payment_frecuency.toString().toLowerCase();
      admissionRequestObject.data.saving_registration_attributes.amount = data.amount == undefined ? "0" : fomartNumber(data.amount);
      admissionRequestObject.data.saving_registration_attributes.status = "activo";
      admissionRequestObject.data.saving_registration_attributes.payment_method = data.payment_method == "ViaNomina" ? "NÓMINA" : "DOMICILIACIÓN"
      admissionRequestObject.data.saving_registration_attributes.number_of_contribution = "0";
      admissionRequestObject.data.saving_registration_attributes.date_of_end = moment().format('YYYY-MM-DD');
      admissionRequestObject.data.saving_registration_attributes.date_of_start = moment().format('YYYY-MM-DD');
    }

    function getBankAccountAttributes(data){
      admissionRequestObject.data.bank_account_attributes[0].account_number = data['0'].account_number.toString().replaceAll("-","");
      admissionRequestObject.data.bank_account_attributes[0].account_type = data['0'].account_type.toString().toLowerCase();
      admissionRequestObject.data.bank_account_attributes[0].bank_name = addUpperCase(data['0'].bank_name);
      admissionRequestObject.data.bank_account_attributes[0].expiration_month = data['0'].expiration_month.toString();
      admissionRequestObject.data.bank_account_attributes[0].expiration_year = data['0'].expiration_year.toString();
    }

    function getBeneficiaries(data){
      admissionRequestObject.data.beneficiaries_attributes = {};
      if(data != null && data.beneficiaries.length > 0){
        var tmp_beneficiaries = {};
        data.beneficiaries.forEach((beneficiarie, key) => {
          
          tmp_beneficiaries[key] = Object.assign({}, beneficiarie);
          tmp_beneficiaries[key].relationship = tmp_beneficiaries[key].relationship.toString().toLowerCase();
          tmp_beneficiaries[key].maternal_name = beneficiarie.maternal_name.trim();
          tmp_beneficiaries[key].middle_name = beneficiarie.middle_name.trim();
          tmp_beneficiaries[key].name = beneficiarie.name.trim();
          tmp_beneficiaries[key].paternal_name = beneficiarie.paternal_name.trim();

          if(tmp_beneficiaries[key].birthday != ""){
            let date = tmp_beneficiaries[key].birthday.split('-');
            if(isNaN(date[1])){
              tmp_beneficiaries[key].birthday = `${ date[0] }-${ getMonthNumberByMonthName(date[1]) }-${ date[2] }`;
            }
          }
          delete tmp_beneficiaries[key].dayBirth;
          delete tmp_beneficiaries[key].monthBirth;
          delete tmp_beneficiaries[key].yearBirth;
        });
        admissionRequestObject.data.beneficiaries_attributes = tmp_beneficiaries; 
      }
    }
    
    function getMonthNumberByMonthName(monthName){
      let monthNumber = getMonths().findIndex( month => month === monthName);
      return (monthNumber+1 < 10)? `0${monthNumber+1}` : monthNumber+1;
    }

    async function finishCapture(){
      
      const economiciRef = economiciRefs.value.validations();
      const savingdataRef = !savingdataRefs.value.touch();
      const laborInformationRef = !laborInformationRefs.value.touch();
      const beneficiariesReft = !beneficiariesRefs.value.touch();
      
      try {
        isLoading.value = true;

        if(laborInformationRef && economiciRef[0] && economiciRef[1] && economiciRef[2] && savingdataRef && beneficiariesReft){

            if (!documentsUploaded.value) {

              let filesNotUploadedText = ""; 
              const files = uploadFileRefs.value.getRequiredFileStatus();

              if(files.length>0 && files.map(file => file.optional).includes(false)){
                files.forEach((data, key) => {
                  if(!data.optional && !data.documentAttached){
                    filesNotUploadedText += `${data.name}<br>`;
                  }
                });

                Swal.fire({
                  title: "Aviso",
                  html: "Favor de adjuntar los documentos: <br>"+filesNotUploadedText,
                  icon: "warning",
                  confirmButtonColor: '#FEB72B',
                })
                isLoading.value = false;
                SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_upload_file_require');
                SwalAlertHelper.setIdAttributeToButtons('button_upload_file_ok', '');
                return;
              }
            }

            var email = ""
            for (let index = 0; index < Object.keys(admissionRequestObject.data.contact_info_attributes).length; index++) {
              let contact = admissionRequestObject.data.contact_info_attributes[index.toString()]
              if(contact.contact_type == 'correo'){
                email = contact.value
              }
            }        

            admissionRequestObject.data.token_auth=sessionStorage.getItem("login");
            let titleGender = admissionRequestObject.data.gender == "hombre" ? "Bienvenido" : "Bienvenida";
            admissionRequestObject.data.title = titleGender + " a FONCABSA";
            admissionRequestObject.data.message = titleGender + " " + admissionRequestObject.data.name + " " + admissionRequestObject.data.middle_name + " " + admissionRequestObject.data.paternal_name + " " + admissionRequestObject.data.maternal_name + ` le informamos que su cuenta fue creada con una contraseña provisional la cual es la siguiente ---, le invitamos a acceder a la plataforma de FONCABSA para actualizar su contraseña en el menú Cambiar contraseña, en el siguiente enlace <a href="${multiApi}/login" target="_black">${multiApi}/login</a>. <br><br>Usuario: ${email}<br>Contraseña: ---`;
            const response = await admissionRequestObject.save(admissionRequestObject.data);
            const documents = getDocuments();
            const documentsWithAdmissionRequestId = setAdmissionRequestIdToAtacchedFiles(response.data.response.admission_request.id, documents);
            await documentObjectClass.value.save(sessionStorage.getItem('login'), documentsWithAdmissionRequestId);
            isLoading.value = false;
            showSavedAdmissionRequestMessage();       
          
        }else{
            isLoading.value = false;

            let messageHTMLvalue = "";

            if(!laborInformationRef){
              messageHTMLvalue = messageHTMLvalue+"Favor de capturar la sección de información laboral"+"<br>";
            }
            
            if(!economiciRef[0] || !economiciRef[1] || !economiciRef[2]){
              messageHTMLvalue = messageHTMLvalue+"Favor de capturar la sección de información económica"+"<br>";
            }

            if(!savingdataRef){
              messageHTMLvalue = messageHTMLvalue+"Favor de capturar la sección datos de ahorro"+"<br>";
            }

            if(!beneficiariesReft){
              messageHTMLvalue = messageHTMLvalue+"Favor de capturar la sección de beneficiarios"+"<br>";
            }
            
            Swal.fire({
              title: "Aviso",
              html: "<strong>Favor de capturar correctamente la(s) seccion(es):</strong><br>" + messageHTMLvalue,
              icon: "error",
            });
        }
        
      } catch (error) {
        console.log("🚀 ~ file: AssistedEnrollmentStep2.vue:355 ~ finishCapture ~ error", error)

        isLoading.value = false;
        Swal.fire({
          title: "Aviso",
          text: "Error al intentar guardar la solicitud",
          icon: "error",
        }); 

      }
    }

    function getUploadFilesData(data) {
      atachedDocuments.value = data.files;
      documentsUploaded.value = data.documentsUploaded;
      admissionRequestObject.data.enrollment_fee_payment_date = data.enrollmentFeePaymentDate;
      admissionRequestObject.data.enrollment_fee_method = "transferencia";
      if (data.enrollmentFeePaymentDate == "") {
        admissionRequestObject.data.enrollment_fee_payment_date = "";
        admissionRequestObject.data.enrollment_fee_method = "";
      }
    }

    function getDocuments() {
      let files = [];
      let identificationFiles = JSON.parse(sessionStorage.getItem("identification-files"));
      let atacchedFiles = atachedDocuments.value;
      
      return files.concat(identificationFiles, atacchedFiles);
    }

    function setAdmissionRequestIdToAtacchedFiles(admissionRequestId, files) {
      return files.map(item => {
        let temp = Object.assign({}, item);
        temp.admission_request_id = admissionRequestId;
        return temp;
      });
    }

    function removeMask(phone) {
      if (phone.includes("(")) {
        phone = phone.replace("(", "");
      }
      if (phone.includes(")")) {
        phone = phone.replace(")", "");
      }
      if (phone.includes("-")) {
        phone = phone.replace("-", "");
      }
      if (phone.includes(" ")) {
        phone = phone.replace(" ", "");
      }
      return phone;
    }

    function showSavedAdmissionRequestMessage() {
      Swal.fire({
        html: `<p style="font-size:14px;font-family:Roboto;font-weight:400;">La solicitud se guardó correctamente</p>`,
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        icon: "success",
        confirmButtonColor: '#FEB72B'
      }).then((result) => {
        if (result.value) {
          redirecTo();
        }
      });
    }

    function redirecTo(){
     var user = getTokenDecoden();
      if (user != null) {
        switch (user.obj['role'] ) {
          case "supervisor":
            router.push({path: "/supervisor/principal"})
            break;
          case "administrator":
            router.push({path: "/admin/principal"})
            break;
          case "loan_analyst":
            router.push({path: "/analyst/principal"})
          break;
          case "member_analyst":
            router.push({path: "/analyst/principal"})
          break;
          case "enroller":
            Swal.fire({
            title: "Solicitud registrada con éxito.",
            text: "¿Deseas capturar otra solicitud?",
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "SI",
            cancelButtonText: "NO",
            confirmButtonColor: '#FEB72B',
            cancelButtonColor: '#FEB72B'
            }).then((result) => {
              if (result.value) {
                router.push({ path:`/${accountType.value}/captura-asistida/seccion/uno`});
              }else{
                router.push({path: "/adviser/principal"});
              }
            });
          break;
        }
      }
    }

    function addUpperCase(value){
      if(value != "" && value != undefined){
          value = value.toString().toUpperCase();
      }
      return value
    }
    
    function fomartNumber(value){
      return value.toString().replace(",","");
    }

    return{
      getEconomici,
      rulesValidations,//Reglas de validaciones de json
      rulesLoaded, //Comprobar si las reglas del json Cargaron corectamente
      finishCapture,
      economiciRefs,
      laborInformationRefs,
      savingdataRefs,
      beneficiariesRefs,
      uploadFileRefs,
      admissionRequestObject,
      getBeneficiaries,
      getSavingRegistrationAttributes,
      getBankAccountAttributes,
      getLaborInformationData,
      getUploadFilesData,
      workCenter,
      isLoading
    }
  },
  components: {
    LaborInformation,
    SavingData,
    Economici,
    Beneficiaries,
    UploadFiles,
    Loading
  }
}
</script>

<style scoped>
.btn-next{
  background: #FFC001;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
</style>
