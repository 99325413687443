<template>
  <div>
    <UploadMultipleFiles 
      :documentsRequired="documentsRequired"
      v-on:upload-file="getUploadFileResponse($event)"
      v-on:deleted-file="getDeletedFileResponse($event)"
      :displayStatusMessage="false" />
  </div>
</template>

<script>
import UploadMultipleFiles from '@/components/Forms/UploadFile/UploadMultipleFiles'
import Document from '@/classes/Document'
export default {
  data () {
    return {
      documentsRequired: [],
      documentObjectClass: null,
      tokenuth:""
    }
  },
  name: 'UploadFilesComponent',
  components: {
    UploadMultipleFiles
  },
  beforeMount() {
    this.documentObjectClass = new Document();
    this.tokenAuth= window.sessionStorage.getItem('login')
    this.getDocumentsRequired();
  },
  methods: {
    async getDocumentsRequired() {
      try {
        const response = await this.documentObjectClass.getDocumentsRequired(this.tokenAuth,'Inscripción asistida');
        // console.log("documents_required",response.data.response.data);
        this.documentsRequired = this.documentObjectClass.createDocumentsRequiredArray(response.data.response.data);
        // console.log(this.documentsRequired);
      } catch (error) {
        
      }
    },
    getRequiredFileStatus(){
      return this.documentsRequired;
    },
    getUploadFileResponse(event) {
      // console.log("event: ",event);
      this.$emit('upload-files-data', event);
    },
    getDeletedFileResponse(event) {
      // console.log("event deleted: ",event);
      this.$emit('upload-files-data', event);
    }
  }
}
</script>