<template>
  <div class="">
   <Loading :isLoading="isLoading" :key="isLoading"/>
    <!-- Title -->
    <div class="flex mb-6">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <img
            src="@/assets/CapturaAsistida/triangle.svg"
            style="width:30px;"
            alt=""
          />
        </div>
      </div>
      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title">Informacion Laboral</label>
        </div>
      </div>
    </div>

    <div v-if="rulesLoaded" class="flex flex-wrap mb-6 md:ml-10">
      <div class="xl:flex w-full">
        <!-- Laboral Status -->
        <div class="flex flex-wrap w-full inline md:w-1/3 px-3 mb-6 md:mb-6">
          <ChooseButton
            :buttons="buttonsWorkStatus"
            v-model.trim="v$.laborInformation.work.workStatus.$model"
            v-on:choose-button="changeLaboralStatus($event)"
            label="Estatus:"
            containerClass="block md:flex "
            buttonClass="w-full md:w-56"
            :selectFirstButton="true"
          />

          <div class="content-text" style="float:left;margin-top:10px">
              <!-- <div v-if="v$.laborInformation.work.workStatus.$dirty && importFlag">
                <div class="input-errors" v-for="error of v$.laborInformation.work.workStatus.$errors" :key="error.$uid">
                  <span class="errors">{{ error.$message }}</span>
                </div>
              </div> -->
          </div>
        </div>
        
        <!-- Work Type -->
        <div
          v-if="workTypesMenu"
          class="flex flex-wrap w-full inline md:w-4/5 px-3 mb-6 md:mb-6"
        >
          <ChooseButton
            :key="buttonsWorkTypeKey"
            :buttons="buttonsWorkType"
            v-model.trim="v$.laborInformation.work.typeOfWork.$model"
            v-on:choose-button="changeWorkType($event)"
            label="Tipo de trabajo:"
            containerClass="block md:flex "
            buttonClass="w-full"
            :selectFirstButton="true"
          />
          <!-- <div class="content-text" style="float:left">
            <div v-if="v$.laborInformation.work.typeOfWork.$error">
              <span class="errors" v-if="!v$.laborInformation.work.typeOfWork.required"
                >El campo es requerido</span
              >
            </div>
          </div> -->
        </div>
      </div>

      <!-- Work -->
      <div class="w-full mt-6">
        <!-- Work center -->
        <div class="w-full md:flex">
          <div
            v-if="displayLaborInformation.work.workCenter.unionSection"
            class="w-full md:w-1/3 px-3 mb-6 md:mb-6"
          >
            <label class="label-input w-full block tracking-wide mb-3" for="select_union_section">
              Seccion Sindical:
            </label>
            <select
              id="select_union_section"
              v-model.trim="v$.laborInformation.work.workCenter.unionSection.$model"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              :class="{'invalid-form ':v$.laborInformation.work.workCenter.unionSection.$error, 'valid-form':!v$.laborInformation.work.workCenter.unionSection.$invalid}"
            >
              <option value="" selected disabled>Seleccione una seccion sindical</option>
              <option
                v-for="unionSections in listUnionSections"
                :key="unionSections.id"
                :value="`${unionSections.section} ${unionSections.acronym}`"
                >{{ unionSections.section.toString().toUpperCase() }}
                {{ unionSections.acronym.toString().toUpperCase() }}</option
              >
            </select>

            <div class="content-text" style="float:left">
              <div v-if="v$.laborInformation.work.workCenter.unionSection.$error">
                <span
                  class="errors"
                  v-if="v$.laborInformation.work.workCenter.unionSection.required"
                  >El campo es requerido</span>
              </div>
            </div>
          </div>
         
          <div
            v-if="displayLaborInformation.work.workCenter.businessLine"
            class="w-full md:w-1/3 px-3 mb-6 md:mb-6"
          >
            <label
              class="label-input w-full block tracking-wide mb-3"
              for="select_business_rotation_working_information"
            >
              Giro Empresarial:
            </label>
            <select
              id="select_business_rotation_working_information"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              :class="{'invalid-form ':v$.laborInformation.work.workCenter.businessLine.$error, 'valid-form':!v$.laborInformation.work.workCenter.businessLine.$invalid}"
              v-model.trim="v$.laborInformation.work.workCenter.businessLine.$model"
            >
              <option value="" selected disabled>Seleccione un giro empresarial</option>
              <option v-for="businessActivity in listBusinessActivities"
                :value="businessActivity.name" :key="businessActivity.id">{{ businessActivity.name }}</option>
            </select>
            <div class="content-text" style="float:left">
              <div v-if="v$.laborInformation.work.workCenter.businessLine.$error">
                <span
                  class="errors"
                  v-if="v$.laborInformation.work.workCenter.businessLine.required"
                  >El campo es requerido</span
                >
              </div>
            </div>
          </div>

          <div
            v-if="displayLaborInformation.work.workCenter.workCenter"
            class="w-full md:w-4/5 px-3 mb-6 md:mb-6"
          >
            <label
              class="label-input w-full block tracking-wide mb-3"
              for="Select_work_place_working_information"
            >
              Lugar de Trabajo:
            </label>
            <select
              id="Select_work_place_working_information"
              v-model.trim="v$.laborInformation.work.workCenter.workCenter.$model"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              :class="{'invalid-form ':v$.laborInformation.work.workCenter.workCenter.$error, 'valid-form':!v$.laborInformation.work.workCenter.workCenter.$invalid}"
            >
              <option value="" selected disabled>Seleccione un lugar de trabajo</option>
              <option
                v-for="affiliated in listAffiliatedBusiness"
                :key="affiliated.id"
                :value="affiliated.acronym"
                >{{ affiliated.name }}</option
              >
              <option value="OTRO">Otros...</option>
            </select>
            <div class="content-text" style="float:left">
              <div v-if="v$.laborInformation.work.workCenter.workCenter.$error">
                <span
                  class="errors"
                  v-if="v$.laborInformation.work.workCenter.workCenter.required"
                  >El campo es requerido</span
                >
              </div>
            </div>
          </div>

          <div
            v-if="displayLaborInformation.work.workCenter.workName"
            class="w-full md:w-4/5 px-3 mb-6 md:mb-6"
          >
            <label class="label-input w-full block tracking-wide mb-3" for="">
              Nombre del Negocio:
            </label>
            <input
              id="input_business_name_working_information"
              type="text"
              v-model.trim="v$.laborInformation.work.workName.$model"
              :class="{'invalid-form ':v$.laborInformation.work.workName.$error, 'valid-form':!v$.laborInformation.work.workName.$invalid}"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            />

            <div class="content-text" style="float:left">
                <div v-if="v$.laborInformation.work.workName.$dirty">
                      <div class="input-errors" v-for="error of v$.laborInformation.work.workName.$errors" :key="error.$uid">
                        <div class="errors">{{ error.$message }}</div>
                    </div>
                  </div>
              </div>

          </div>

          <div
            v-if="displayLaborInformation.work.freeWorkCenterName"
            class="w-full md:w-4/5 px-3 mb-6 md:mb-6"
          >
            <label class="label-input w-full block tracking-wide mb-3" for="input_workplace_working_information">
              Centro de trabajo:
            </label>
            <input
              id="input_workplace_working_information"
              type="text"
              v-model.trim="v$.laborInformation.work.freeWorkCenterName.$model"
              :class="{'invalid-form ':v$.laborInformation.work.freeWorkCenterName.$error, 'valid-form':!v$.laborInformation.work.freeWorkCenterName.$invalid}"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            />

            <div class="content-text" style="float:left">
                <div v-if="v$.laborInformation.work.freeWorkCenterName.$dirty">
                      <div class="input-errors" v-for="error of v$.laborInformation.work.freeWorkCenterName.$errors" :key="error.$uid">
                        <div class="errors">{{ error.$message }}</div>
                    </div>
                  </div>
              </div>
          </div>
        </div>

        <!-- Other -->
        <div class="w-full md:w-2/5">
          <div
            v-if="displayLaborInformation.work.workCenter.workCenterName"
            class="w-full px-3 mb-6 md:mb-6"
          >
            <label
              class="label-input w-full block tracking-wide mb-3"
              for="input_workplace_name_working_information"
            >
              Nombre del Centro de trabajo:
            </label>
            <input
              id="input_workplace_name_working_information"
              type="text"
              v-model.trim="v$.laborInformation.work.workCenter.workCenterName.$model"
              :class="{'invalid-form ':v$.laborInformation.work.workCenter.workCenterName.$error, 'valid-form':!v$.laborInformation.work.workCenter.workCenterName.$invalid}"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            />
            <div class="content-text" style="float:left">
                <div v-if="v$.laborInformation.work.workCenter.workCenterName.$dirty">
                      <div class="input-errors" v-for="error of v$.laborInformation.work.workCenter.workCenterName.$errors" :key="error.$uid">
                        <div class="errors">{{ error.$message }}</div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <!-- End Other -->






        <!-- End Work center -->
      </div>
      <!-- End Work -->

      <!-- Address -->
      <div class="md:flex w-full mt-6">
        <div
          v-if="displayLaborInformation.address"
          class="w-full md:w-1/3 px-3 mb-6 md:mb-6"
        >
          <label
            class="label-input w-full block tracking-wide mb-3"
            for="input_zip_code_working_information"
          >
            Código Postal:
          </label>
          <input
            id="input_zip_code_working_information"
            type="number"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            :class="{'invalid-form ':v$.laborInformation.address.postalCode.$error, 'valid-form':!v$.laborInformation.address.postalCode.$invalid}"
            v-model.trim="v$.laborInformation.address.postalCode.$model"
            @blur="validatePostalCode($event.target.value)"
            onKeyPress="if(this.value.length==5) return false;"
          />


          <div class="content-text" style="float:left">
            <div v-if="v$.laborInformation.address.postalCode.$dirty">
              <div class="input-errors" v-for="error of v$.laborInformation.address.postalCode.$errors" :key="error.$uid">
                <div class="errors">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full md:w-4/5 px-3 mb-6 md:mb-6">
          <div
            v-if="displayLaborInformation.address"
            class="w-full md:w-1/2 px-3 mb-6 md:mb-6"
          >
            <label
              class="label-input w-full block tracking-wide mb-3"
              for="label_state_working_information"
            >
              Estado:
            </label>
            <p
              v-if="
                typeof location.state.nombre != 'undefined' ? true : false
              "
              id="label_state_working_information"
              class="font-bold mt-2 uppercase"
            >
              {{ location.state.nombre }}
            </p>
            <p
              v-if="!location.state.nombre"
              id="label_state_working_information"
              class="font-bold mt-2"
            ></p>
          </div>
          <div
            v-if="displayLaborInformation.address"
            class="w-full md:w-1/2 px-3 mb-6 md:mb-6"
          >
            <label
              class="label-input w-full block tracking-wide mb-3"
              for="label_municipality_working_information"
            >
              Municipio:
            </label>
            <p
              v-if="
                typeof location.municipality.nombre != 'undefined'
                  ? true
                  : false
              "
              id="label_municipality_working_information"
              class="font-bold mt-2 uppercase"
            >
              {{ location.municipality.nombre }}
            </p>
            <p
              v-if="!location.municipality"
              id="label_municipality_working_information"
              class="font-bold mt-2"
            ></p>
          </div>
          <div
            v-if="displayLaborInformation.address"
            class="w-full md:w-1/2 px-3 mb-6 md:mb-6"
          >
            <label
              class="label-input w-full block tracking-wide mb-3"
              for="label_city_working_information"
            >
              Ciudad:
            </label>
            <p
              v-if="
                typeof location.municipality.nombre != 'undefined'
                  ? true
                  : false
              "
              id="label_city_working_information"
              class="font-bold mt-2 uppercase"
            >
              {{ location.city.nombre ? location.city.nombre : "N/A" }}
            </p>
            <p
              v-if="!location.municipality"
              id="label_city_working_information"
              class="font-bold mt-2"
            ></p>
          </div>
        </div>
      </div>



      <div v-if="displayLaborInformation.address" class="md:flex w-full mt-6">
        <div class="w-full md:w-3/5 px-3 mb-6 md:mb-6">
          <label
            class="label-input w-full block tracking-wide mb-3"
            for="select_neightboorhood_working_information"
          >
            Colonia:
          </label>
          <select
            id="select_neightboorhood_working_information"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            :class="{'invalid-form ':v$.laborInformation.address.suburb.$error, 'valid-form':!v$.laborInformation.address.suburb.$invalid}"
            v-model.trim="v$.laborInformation.address.suburb.$model"
          >
            <option value="" selected disabled>Seleccione una colonia</option>
            <option
              v-for="suburbs in location.suburbs"
              :key="suburbs.id"
              :value="suburbs.nombre"
              >{{ suburbs.nombre }}</option
            >
          </select>
          <div class="content-text" style="float:left">
            <div v-if="v$.laborInformation.address.suburb.$dirty">
              <div class="input-errors" v-for="error of v$.laborInformation.address.suburb.$errors" :key="error.$uid">
                <div class="errors">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div v-if="displayLaborInformation.address" class="md:flex w-full mt-6">
        <div class="w-full md:w-3/5 px-3 mb-6 md:mb-6">
          <label
            class="label-input w-full block tracking-wide mb-3"
            for="input_street_working_information"
          >
            Calle:
          </label>
          <input
            id="input_street_working_information"
            type="text"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            :class="{'invalid-form ':v$.laborInformation.address.street.$error, 'valid-form':!v$.laborInformation.address.street.$invalid}"
            v-model.trim="v$.laborInformation.address.street.$model"
          />
          <div class="content-text" style="float:left">
            <div v-if="v$.laborInformation.address.street.$dirty">
              <div class="input-errors" v-for="error of v$.laborInformation.address.street.$errors" :key="error.$uid">
                <p class="errors">{{ error.$message }}</p> 
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-2/5 px-3 mb-6 md:mb-6">
          <label
            class="label-input w-full block tracking-wide mb-3"
            for="input_exterior_number_working_information"
          >
            Número Exterior:
          </label>
          <input
            id="input_exterior_number_working_information"
            type="text"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            :class="{'invalid-form ':v$.laborInformation.address.exteriorNumber.$error, 'valid-form':!v$.laborInformation.address.exteriorNumber.$invalid}"
            v-model.trim="v$.laborInformation.address.exteriorNumber.$model"
            v-maska="'#####'"
          />
           <div class="content-text" style="float:left">
            <div v-if="v$.laborInformation.address.exteriorNumber.$dirty">
              <div class="input-errors" v-for="error of v$.laborInformation.address.exteriorNumber.$errors" :key="error.$uid">
                <span class="errors">{{ error.$message }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-2/5 px-3 mb-6 md:mb-6">
          <label
            class="label-input w-full block tracking-wide mb-3"
            for="input_exterior_number_working_information"
          >
            Número Interior:
          </label>
          <input
            id="input_exterior_number_working_information"
            type="text"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            :class="{'invalid-form ':v$.laborInformation.address.interiorNumber.$error, 'valid-form':!v$.laborInformation.address.interiorNumber.$invalid}"
            v-model.trim="v$.laborInformation.address.interiorNumber.$model"
          />
           <div class="content-text" style="float:left">
            <div v-if="v$.laborInformation.address.interiorNumber.$dirty">
              <div class="input-errors" v-for="error of v$.laborInformation.address.interiorNumber.$errors" :key="error.$uid">
                <span class="errors">{{ error.$message }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Address -->





      <!-- Telephone -->
      <div v-if="displayLaborInformation.telephone" class="flex md:w-3/5 mt-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-6">
          <label
            class="label-input w-full block tracking-wide mb-3"
            for="input_phone_working_information"
          >
            Teléfono:
          </label>
          <input
            id="input_phone_working_information"
            type="text"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            :class="{'invalid-form ':v$.laborInformation.telephone.number.$error, 'valid-form':!v$.laborInformation.telephone.number.$invalid}"
            v-model.trim="v$.laborInformation.telephone.number.$model"
            v-maska="'### ###-####'"
          />
          <div class="content-text" style="float:left">
            <div v-if="v$.laborInformation.telephone.number.$dirty">
              <div class="input-errors" v-for="error of v$.laborInformation.telephone.number.$errors" :key="error.$uid">
                <span class="errors">{{ error.$message }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-6">
          <label
            class="label-input w-full block tracking-wide mb-3"
            for="input_phone_extension_working_information"
          >
            Extensión:
          </label>
          <input
            id="input_phone_extension_working_information"
            type="text"
            class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
            :class="{'invalid-form ':v$.laborInformation.telephone.lada.$error, 'valid-form':!v$.laborInformation.telephone.lada.$invalid}"
            v-model.trim="v$.laborInformation.telephone.lada.$model"
          />
         <div class="content-text" style="float:left">
            <div v-if="v$.laborInformation.telephone.lada.$dirty">
              <div class="input-errors" v-for="error of v$.laborInformation.telephone.lada.$errors" :key="error.$uid">
                <span class="errors">{{ error.$message }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Telephone -->

      <!-- Questions Public Charge  -->
      <div class="md:flex w-full mt-10 md:mt-6 mb-6 md:mb-0">
        <div class="md:block md:w-1/2">
            <div
            v-if="displayLaborInformation.publicCharge.questionOne"
            class="flex flex-wrap px-3 mb-6 md:mb-6"
          >
            <ChooseButton
              :buttons="buttonsPublicCharge"
              v-on:choose-button="changePublicCharge($event)"
              label="¿Ha tenido algún cargo público?"
              containerClass="block md:flex "
              buttonClass="w-full md:w-56"
              v-model.trim="v$.laborInformation.publicCharge.questionOne.$model"
            />
          </div>
          <div
            v-if="displayLaborInformation.publicCharge.questionTwo"
            class="w-full px-3 my-3 md:my-0"
          >
            <label
              class="label-input w-full block tracking-wide mb-6"
              for="button_mexico_birthplace"
            >
              ¿Qué cargo público ha desempeñado?
            </label>
            <input
              id="input_public_office_owner"
              type="text"
              :class="{'invalid-form ':v$.laborInformation.publicCharge.questionTwo.$error, 'valid-form':!v$.laborInformation.publicCharge.questionTwo.$invalid}"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              v-model.trim="v$.laborInformation.publicCharge.questionTwo.$model"
            />

            <div class="content-text" style="float:left">
              <div v-if="v$.laborInformation.publicCharge.questionTwo.$dirty">
                <div class="input-errors" v-for="error of v$.laborInformation.publicCharge.questionTwo.$errors" :key="error.$uid">
                  <span class="errors">{{ error.$message }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="displayLaborInformation.publicCharge.questionTwo"
          class="xl:flex w-full px-3"
        >
          <div class="px-1 py-3">
            <label
              class="label-input w-full block tracking-wide mb-6"
              for="button_mexico_birthplace"
            >
              De:
            </label>

            <DateComponent
              :idYear="'select_start_date_year_owner'"
              :idMonth="'select_start_date_month_owner'"
              :idDay="'select_start_date_day_owner'"
              :isRemaining="false"
              v-model.trim="v$.laborInformation.publicCharge.startDate.$model"
              v-on:get-complete-date-selected="changePublicChargeStartDate($event)" name="select_birthdate_partner" id="select_birthdate_partner" />

            <div class="content-text" style="float:left">
              <div v-if="v$.laborInformation.publicCharge.startDate.$dirty">
                <div class="input-errors" v-for="error of v$.laborInformation.publicCharge.startDate.$errors" :key="error.$uid">
                  <span class="errors">{{ error.$message }}</span>
                </div>
              </div>
            </div>

          </div>
          <div class="px-1 py-3">
            <label
              class="label-input w-full block tracking-wide mb-6"
              for="button_mexico_birthplace"
            >
              A:
            </label>

            <DateComponent 
              :idYear="'select_end_date_year_owner'"
              :idMonth="'select_end_date_month_owner'"
              :idDay="'select_end_date_day_owner'" 
              :isRemaining="false"
              v-on:get-complete-date-selected="changePublicChargeEndDate($event)"
              v-model.trim="v$.laborInformation.publicCharge.endDate.$model" />
              
             <div class="content-text" style="float:left">
              <div v-if="v$.laborInformation.publicCharge.endDate.$dirty">
                <div class="input-errors" v-for="error of v$.laborInformation.publicCharge.endDate.$errors" :key="error.$uid">
                  <span class="errors">{{ error.$message }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Questions Public Charge  -->

      <!-- Questions Family Public Charge  -->
      <div class="md:flex w-full mt-10 md:mt-6 mb-6 md:mb-0">
        <div class="md:block md:w-1/2">
          <div
            v-if="displayLaborInformation.publicChargeFamily.questionOne"
            class="w-full px-3 my-3 md:my-0"
          >
            <ChooseButton
              :buttons="buttonsFamilyPublicCharge"
              v-on:choose-button="changeFamilyPublicCharge($event)"
              label="¿Algún familiar ha tenido cargo público? (Cónyuge, Hijo(a), Padre/Madre, Hermano, Concubino(a))"
              containerClass="block md:flex "
              buttonClass="w-full md:w-56"
              v-model.trim="laborInformation.publicChargeFamily.questionOne.$model"
            />

            <div
            v-if="displayLaborInformation.publicChargeFamily.questionTwo"
            class="w-full px-3 my-3 md:my-0"
          >
            <label
              class="label-input w-full block tracking-wide mb-6"
              for="button_mexico_birthplace"
            >
              ¿Qué cargo público ha desempeñado?
            </label>
            <input
              id="input_public_office_family"
              type="text"
              :class="{'invalid-form ':v$.laborInformation.publicChargeFamily.questionTwo.$error, 'valid-form':!v$.laborInformation.publicChargeFamily.questionTwo.$invalid}"
              class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
              v-model.trim="v$.laborInformation.publicChargeFamily.questionTwo.$model"
            />
  
            <div class="content-text" style="float:left">
              <div v-if="v$.laborInformation.publicChargeFamily.questionTwo.$dirty">
                <div class="input-errors" v-for="error of v$.laborInformation.publicChargeFamily.questionTwo.$errors" :key="error.$uid">
                  <span class="errors">{{ error.$message }}</span>
                </div>
              </div>
            </div>
          </div>
  
          </div>

        </div> 

        <div
            v-if="displayLaborInformation.publicChargeFamily.questionTwo"
            class="xl:flex w-full px-3"
          >
            <div class="px-1 py-3">
              <label
                class="label-input w-full block tracking-wide mb-6"
                for="button_mexico_birthplace"
              >
                De:
              </label>
  
              <DateComponent
                :idYear="'select_start_date_year_family'"
                :idMonth="'select_start_date_month_family'"
                :idDay="'select_start_date_day_family'"
                :isRemaining="false"
                v-model.trim="v$.laborInformation.publicChargeFamily.startDate.$model"
                v-on:get-complete-date-selected="changeFamilyPublicChargeStartDate($event)"
              />
  
            <div class="content-text" style="float:left">
              <div v-if="v$.laborInformation.publicChargeFamily.startDate.$dirty">
                <div class="input-errors" v-for="error of v$.laborInformation.publicChargeFamily.startDate.$errors" :key="error.$uid">
                  <span class="errors">{{ error.$message }}</span>
                </div>
              </div>
            </div>
              
            </div>
            <div class="px-1 py-3">
              <label
                class="label-input w-full block tracking-wide mb-6"
                for="button_mexico_birthplace"
              >
                A:
              </label>
  
              <DateComponent
                :idYear="'select_end_date_year_family'"
                :idMonth="'select_end_date_month_family'"
                :idDay="'select_end_date_day_family'"
                :isRemaining="false"
                v-model.trim="v$.laborInformation.publicChargeFamily.endDate.$model" 
                v-on:get-complete-date-selected="changeFamilyPublicChargeEndDate($event)"
                />
  
            <div class="content-text" style="float:left">
              <div v-if="v$.laborInformation.publicChargeFamily.endDate.$dirty">
                <div class="input-errors" v-for="error of v$.laborInformation.publicChargeFamily.endDate.$errors" :key="error.$uid">
                  <span class="errors">{{ error.$message }}</span>
                </div>
              </div>
            </div>
                
              
            </div>
          </div>
        
      </div>
    </div>
      <!-- End Questions Family Public Charge  -->
    </div>
  <!-- </div> -->
</template>

<script >
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, maxLength, requiredIf} from '@vuelidate/validators';

import Affiliated from "@/classes/Affiliated";
import ChooseButton from "@/components/Forms/Buttons/ChooseButton.vue";

import DateComponent from "@/components/Forms/Selects/Date";
import Loading from '@/components/Loading/VueLoading.vue'

import("@/assets/css/referredperson.css");
import { isBeforeDate, isAfterDate, isEqualDate, getCurrentDate } from '@/helpers/dates.js';

export default {
  name: "LaborInformation",
  setup(){
    return { v$:useVuelidate()}
  },
  data() {
    return {
      buttonsWorkStatus: [{
          id: "button_active_status_working_information",
          value: true,
          text: "Activo",
        },
        {
          id: "button_retired_status_working_information",
          value: false,
          text: "Jubilado",
        },
        {
          id: "button_student_status_working_information",
          value: null,
          text: "Estudiante",
        },
      ],
      buttonsWorkType: [{
          id: "button_employee_status_working_information",
          value: "Empleado",
          text: "Empleado",
        },
        {
          id: "button_own_businesss_working_information",
          value: "Negocio Propio",
          text: "Negocio propio",
        },
        {
          id: "button_education_worker_working_information",
          value: "Trabajador de la educacion",
          text: "Trabajador de la Educación",
        },
      ],
      buttonsPublicCharge: [{
          id: "radiobutton_public_office_yes_owner",
          value: true,
          text: "Sí",
        },
        {
          id: "radiobutton_public_office_no_owner",
          value: false,
          text: "No",
        },
      ],
      buttonsFamilyPublicCharge: [{
          id: "radiobutton_public_office_yes_family",
          value: true,
          text: "Sí",
        },
        {
          id: "radiobutton_public_office_no_family",
          value: false,
          text: "No",
        },
      ],
      listAffiliatedBusiness: [],
      listBusinessActivities: [],
      listUnionSections: [],
      affiliatedObjectsClass: null,
      postalCode: null,
      workCenter: "",
      workTypesMenu: false,
      location: {
        suburbs: [],
        state: {},
        city: {},
        municipality: {},
      },
      laborInformation: {
        work: {

          workStatus: true,
          typeOfWork: "Empleado",
          workName: "",
          freeWorkCenterName: "",
          workCenter: {
            businessLine: "",
            workCenter: "",
            workCenterName: "",
            unionSection: "",
          },
        },
        address: {
          location: {
            state: "",
            city: "",
            municipality: "",
          },
          postalCode: "",
          suburb: "",
          street: "",
          interiorNumber: "",
          exteriorNumber: "",
        },
        telephone: {
          number: "",
          lada: "",
        },
        publicCharge: {
          questionOne: false,
          questionTwo: "",
          startDate: "",
          endDate: "",
        },
        publicChargeFamily: {
          questionOne: false,
          questionTwo: "",
          startDate: "",
          endDate: "",
        },
      },
      displayLaborInformation: {
        work: {
          workName: false,
          freeWorkCenterName: false,
          workCenter: {
            businessLine: false,
            workCenter: false,
            workCenterName: false,
            unionSection: false,
          },
        },
        address: false,
        telephone: false,
        publicCharge: {
          questionOne: false,
          questionTwo: false,
        },
        publicChargeFamily: {
          questionOne: false,
          questionTwo: false,
        },
      },
      buttonsWorkTypeKey: 0,
      isLoading:false
    };
  },

  props: {
    rulesValidations: {
      type: Object,
      default: () => {}
    },
    rulesLoaded: {
      type: Boolean,
      default: false
    },
  },

  validations() {
    return {

      laborInformation: {
        work: {
          workStatus: {
            required: true,
          },
          typeOfWork: {
            required: requiredIf(function() {
              if (!this.rulesValidations.laborInformation.work.typeOfWork.required) {
                return false;
              }
              return this.laborInformation.work.workStatus;
            }),
          },
          workName: {
            required: helpers.withMessage(()=>{
              if (!this.rulesValidations.laborInformation.work.workName.required) {
                return "";
              }
              return (this.laborInformation.work.workCenter.workCenterName != "OTRO" &&
                  this.laborInformation.work.typeOfWork == "Negocio Propio" &&
                  this.laborInformation.work.workStatus == true) ?
                  "El campo nombre negocio es requerido." : "";
            }
            ,requiredIf(function() {
              if (!this.rulesValidations.laborInformation.work.workName.required) {
                return false;
              }
              return (this.laborInformation.work.workCenter.workCenterName != "OTRO" &&
                  this.laborInformation.work.typeOfWork == "Negocio Propio" &&
                  this.laborInformation.work.workStatus == true) ?
                  true : false;
            })),
            minLength: helpers.withMessage("El campo debe contener mínimo "+this.rulesValidations.laborInformation.work.workName.minLength+" caracteres",minLength(
               this.rulesValidations.laborInformation.work.workName.minLength
              )),
            maxLength: helpers.withMessage("El campo debe contener máximo "+this.rulesValidations.laborInformation.work.workName.maxLength+" caracteres",maxLength(
               this.rulesValidations.laborInformation.work.workName.maxLength
              )),
          },
          freeWorkCenterName: {
            required: helpers.withMessage(()=>{
              if (!this.rulesValidations.laborInformation.work.freeWorkCenterName.required) {
                return "";
              }
              return (this.laborInformation.work.typeOfWork == "Trabajador de la educacion" &&
                  this.laborInformation.work.workStatus == true) ?
                "El campo centro de trabajo es requrido" :
                "";
              },
              requiredIf(function() {
              if (!this.rulesValidations.laborInformation.work.freeWorkCenterName.required) {
                return false;
              }
              return (this.laborInformation.work.typeOfWork == "Trabajador de la educacion" &&
                  this.laborInformation.work.workStatus == true) ?
                true :
                false;
            })),
             minLength: helpers.withMessage("El campo debe contener mínimo "+this.rulesValidations.laborInformation.work.freeWorkCenterName.minLength+" caracteres",minLength(this.rulesValidations.laborInformation.work.freeWorkCenterName.minLength)),
             maxLength: helpers.withMessage("El campo debe contener máximo "+this.rulesValidations.laborInformation.work.freeWorkCenterName.maxLength+" caracteres",maxLength(this.rulesValidations.laborInformation.work.freeWorkCenterName.maxLength)),
          },
          workCenter: {
            businessLine: {
              required: requiredIf(function() {
 
                if (!this.rulesValidations.laborInformation.work.workCenter.businessLine.required) {
                  return false;
                }

                if (
                  (this.laborInformation.work.workStatus == true &&
                    this.laborInformation.work.typeOfWork == "Empleado") ||
                  (this.laborInformation.work.workStatus &&
                    this.laborInformation.work.typeOfWork == "Negocio Propio")
                ) {
                  return true;
                } else if (this.laborInformation.work.workStatus == false ) {
                  return true;
                }
              }),
            },
            workCenter: {
              required: requiredIf(function() {
                if (!this.rulesValidations.laborInformation.work.workCenter.workCenter.required) {
                  return false;
                }

                return (this.laborInformation.work.workCenter.workCenterName != "OTRO" &&
                  this.laborInformation.work.typeOfWork == "Empleado" &&
                  this.laborInformation.work.workStatus == true) ?
                  true :
                  false;
              }),
            },
            workCenterName: {
              required: helpers.withMessage(
                ()=>{
                   if (!this.rulesValidations.laborInformation.work.workCenter.workCenterName.required==false && this.laborInformation.work.workCenter.workCenter != "OTRO") {
                    return "El campo nombre del centro de trabajo es requerido";
                  }

                  return (this.laborInformation.work.workCenter.workCenterName != "OTRO" &&
                    this.laborInformation.work.typeOfWork == "Empleado" &&
                    this.laborInformation.work.workStatus == true) ?
                    "El campo nombre del centro de trabajo es requerido" :
                    "";
                }
                ,requiredIf(function() {
                
                if (!this.rulesValidations.laborInformation.work.workCenter.workCenterName.required==false && this.laborInformation.work.workCenter.workCenter != "OTRO") {
                  return false;
                }

                return (this.laborInformation.work.workCenter.workCenterName != "OTRO" &&
                  this.laborInformation.work.typeOfWork == "Empleado" &&
                  this.laborInformation.work.workStatus == true) ?
                  true :
                  false;
              })),
              minLength: helpers.withMessage("El campo nombre del centro de trabajo tiene que tener como mínimo "+this.rulesValidations.laborInformation.work.workCenter.workCenterName.minLength+" caracteres",minLength(this.rulesValidations.laborInformation.work.workCenter.workCenterName.minLength)),
              maxLength:  helpers.withMessage("El campo nombre del centro de trabajo tiene que tener como máximo "+this.rulesValidations.laborInformation.work.workCenter.workCenterName.maxLength+" caracteres",maxLength(this.rulesValidations.laborInformation.work.workCenter.workCenterName.maxLength)),
            },
            unionSection: {
              required: requiredIf(function() {
                if (!this.rulesValidations.laborInformation.work.workCenter.unionSection.required) {
                  return false;
                }
                return (this.laborInformation.work.workCenter.workCenterName != "OTRO" &&
                  this.laborInformation.work.typeOfWork == "Trabajador de la educacion" &&
                  this.laborInformation.work.workStatus == true) ?
                  true :
                  false;
              }),
            },
          },
        },
        address: {
          postalCode: {
            required: helpers.withMessage("El campo codigo postal es requerido.", requiredIf(function() {
              if (!this.rulesValidations.laborInformation.address.postalCode.required) {
                return false;
              }
              return this.laborInformation.work.workStatus;
            })),
            minLength: helpers.withMessage("El campo còdigo postal tiene que tener mínimo "+this.rulesValidations.laborInformation.address.postalCode.minLength+" caracteres", minLength(this.rulesValidations.laborInformation.address.postalCode.minLength)),
            maxLength: helpers.withMessage("El campo còdigo postal tiene que tener máximo "+this.rulesValidations.laborInformation.address.postalCode.maxLength+" caracteres",maxLength(this.rulesValidations.laborInformation.address.postalCode.maxLength)),
            numericValidator:helpers.withMessage("El campo código postal solo puede contener números.",(value)=> {
              if(value!=""){
                return new RegExp(/^[0-9]+$/).test(value)
              }
              else return true;
            }),
          },
          suburb: {
            required: helpers.withMessage("El campo colonia es requerido. ",requiredIf(function() {
              if (!this.rulesValidations.laborInformation.address.suburb.required) {
                return false;
              }
              return this.laborInformation.work.workStatus;
            })),
          },
          street: {
            required:helpers.withMessage("El campo calle es requerido.",requiredIf(function() {
              if (!this.rulesValidations.laborInformation.address.street.required) {
                return false;
              }
              return this.laborInformation.work.workStatus;
            })),
            minLength:helpers.withMessage("El campo calle mínimo tiene que tener "+this.rulesValidations.laborInformation.address.street.minLength+" caracteres",minLength(this.rulesValidations.laborInformation.address.street.minLength)),
            maxLength:helpers.withMessage("El campo calle mínimo tiene que tener "+this.rulesValidations.laborInformation.address.street.maxLength+" caracteres",maxLength(this.rulesValidations.laborInformation.address.street.maxLength)),
            alphanumeric:helpers.withMessage("El campo no permite caracteres especiales.",(value)=>{
              if(value!="")
              return new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/).test(value)
              else return true;
            })
          },
          
          interiorNumber: {
            required: helpers.withMessage("El campo número interior es requerido.",requiredIf(function() {
              return this.rulesValidations.laborInformation.address.interiorNumber.required;
            })),
            minLength: helpers.withMessage("El campo número interior tiene que tener mínimo "+this.rulesValidations.laborInformation.address.interiorNumber.minLength+" caracteres",minLength(this.rulesValidations.laborInformation.address.interiorNumber.minLength)),
            maxLength: helpers.withMessage("El campo número interior tiene que tener máximo "+this.rulesValidations.laborInformation.address.interiorNumber.maxLength+" caracteres",maxLength(this.rulesValidations.laborInformation.address.interiorNumber.maxLength)),
            numericValidator:helpers.withMessage("",(value, obj)=> {
              if(value!=""){
                console.log(obj.interiorNumber)
                obj.interiorNumber = value.replace(/[^0-9-a-zA-Z-\/\s]+/, "")
                this.interiorNumber = obj.interiorNumber.replace(/[^0-9-a-zA-Z-\/\s]+/, "")
              }
              return true;
            }),
          },
          exteriorNumber: {
            required: helpers.withMessage("El campo número exterior es requerido.",requiredIf(function() {
              return this.laborInformation.work.workStatus;
            })),
            minLength: helpers.withMessage("El campo número exterior tiene que tener mínimo "+this.rulesValidations.laborInformation.address.exteriorNumber.minLength+" caracteres",minLength(this.rulesValidations.laborInformation.address.exteriorNumber.minLength)),
            maxLength: helpers.withMessage("El campo número exterior tiene que tener mínimo "+this.rulesValidations.laborInformation.address.exteriorNumber.maxLength+" caracteres",maxLength(this.rulesValidations.laborInformation.address.exteriorNumber.maxLength)),
            numericValidator:helpers.withMessage("El campo número exterior solo puede contener números.",(value)=> {
              if(value!=""){
                return new RegExp(/^[0-9]+$/).test(value)
              }
              else return true;
            }),
          },
        },
        telephone: {
          number: {
            required: helpers.withMessage("El campo teléfono es requerido.",requiredIf(function() {  
              
              if(!this.rulesValidations.laborInformation.work.workStatus.required || this.rulesValidations.laborInformation.work.workStatus.required == null){
                return false;
              }
              
              if (!this.rulesValidations.laborInformation.telephone.number.required) {
                return false;
              }

              if (this.laborInformation.work.workStatus) {
                return true;
              }
              return false;
            })),
            minLength: helpers.withMessage( "El campo teléfono debe contener mínimo "+this.rulesValidations.laborInformation.telephone.number.minLength+" dígitos",  function(value){ 
              if(!this.rulesValidations.laborInformation.work.workStatus.required || this.rulesValidations.laborInformation.work.workStatus.required == null || !this.laborInformation.work.workStatus){
                return true;
              }

              value = value.toString().replace(" ", "");
              value = value.toString().replace("-", "");
              return (value.toString().length < this.rulesValidations.laborInformation.telephone.number.minLength)? false : true;
            }),
            maxLength: helpers.withMessage("El campo teléfono debe contener máximo "+this.rulesValidations.laborInformation.telephone.number.maxLength+" dígitos",  function(value){ 
              value = value.toString().replace(" ", "");
              value = value.toString().replace("-", "");
              return (value.toString().length > this.rulesValidations.laborInformation.telephone.number.maxLength)? false : true;
            }),

            isLada: helpers.withMessage("El campo teléfono no puede quedar vacío", function(value, obj){
              if(obj.lada.length > 0 && value == ""){
                this.v$.laborInformation.telephone.$touch();
                return false
              }
              return true;
            })

          },
          lada: {
            minLength: helpers.withMessage("El campo extensión debe contener mínimo "+this.rulesValidations.laborInformation.telephone.lada.minLength+" dígitos",minLength(this.rulesValidations.laborInformation.telephone.lada.minLength)),
            maxLength: helpers.withMessage("El campo extensión debe contener máximo "+this.rulesValidations.laborInformation.telephone.lada.maxLength+" dígitos",maxLength(this.rulesValidations.laborInformation.telephone.lada.maxLength)),
           numericValidator:helpers.withMessage("El campo extensión solo puede contener números.",(value)=> {
             if(value!=""){
               return new RegExp(/^[0-9]+$/).test(value)
             }
             else return true;
           })
          },
        },
        publicCharge: {
          questionOne: {
            required: requiredIf(function() {
              return false;
            })
          },
          questionTwo: {
            required: helpers.withMessage("El campo cargo público ha desempeñado es requerido. ",requiredIf(function(value, e) {
              return (!e.questionOne)? false: true;
            })),
            minLength: helpers.withMessage("El campo cargo público debe contener mínimo 10 caracteres",minLength(10)) ,
            maxLength: helpers.withMessage("El campo cargo público debe contener máximo 50 caracteres",maxLength(50)) ,
            alphaNumAndDotValidator: helpers.withMessage("El campo cargo público no admite caracteres especiales. ",(value)=>{
               if(value!="")
              return new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/).test(value)
              else return true;
            }),
            $autoDirty: true
          },
          startDate: {
            required: helpers.withMessage("El campo fecha inicio es requerido.",requiredIf(function(value, e) {
              return (!e.questionOne)? false: true;
            })),
            dateNotAllowed: helpers.withMessage('El campo fecha debe ser menor a la fecha actual.', function(value, e){
              if(!e.questionOne) return true;
              var currentDate = getCurrentDate()
              return !isEqualDate({start_date: currentDate, end_date: value});
            }),
            isValid: helpers.withMessage("El campo debe tener una fecha menor a la fecha final.", function(value, e){

              if(!e.questionOne){
                return true;
              }

              return !isAfterDate({start_date: value, end_date: e.endDate});
            }),
            equal: helpers.withMessage("El campo fecha inicio no puede ser igual a la fecha final.", function(value, e) {
              if(!e.questionOne || e.endDate == ""){
                return true;
              }
              return !isEqualDate({start_date: value, end_date: e.endDate});
            }),
            $autoDirty: true
          },
          endDate: {
            required: helpers.withMessage("El campo fecha final es requerido.", requiredIf(function(value, e) {
              return (!e.questionOne)? false: true;
            })),
            isValid: helpers.withMessage("El campo debe tener una fecha mayor a la fecha inicial.",function(value, e){
              if(!e.questionOne){
                return true;
              }
              
              return !isBeforeDate({start_date: value, end_date: e.startDate});
            }),
            $autoDirty: true
          },
        },
        publicChargeFamily: {
          questionOne: {
            required: requiredIf(function() {
              return false;
            })
          },
          questionTwo: {
            required: helpers.withMessage("El campo cargo público ha desempeñado es requerido.",requiredIf(function(value, e) {
              return (!e.questionOne)? false: true;
            })),
            minLength: helpers.withMessage("El campo cargo público debe contener mínimo 10 caracteres",minLength(10)),
            maxLength: helpers.withMessage("El campo cargo público debe contener máximo 50 caracteres",maxLength(50)),
            alphaNumAndDotValidator:helpers.withMessage("El campo cargo público no admite caracteres especiales. ",(value)=>{
              if(value!="")
              return new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/).test(value)
              else return true;
            }),
            $autoDirty: true
          },
          startDate: {
            required:helpers.withMessage("El campo fecha inicio es requerida.", requiredIf(function(value, e) {
              return (!e.questionOne)? false: true;
            })),
            dateNotAllowed: helpers.withMessage('El campo fecha debe ser menor a la fecha actual.', function(value, e){
              if(!e.questionOne) return true;
              var currentDate = getCurrentDate()
              return !isEqualDate({start_date: currentDate, end_date: value});
            }),
            isValid: helpers.withMessage("El campo debe tener una fecha menor a la fecha final.", function(value, e){
              if(!e.questionOne){
                return true;
              }

              return !isAfterDate({start_date: value, end_date: e.endDate});
            }),
            equal: helpers.withMessage("El campo fecha inicio no puede ser igual a la fecha final.", function(value, e) {
              if(!e.questionOne || e.endDate == ""){
                return true;
              }

              console.log("isEqualDate({start_date: value, end_date: e.endDate}):", isEqualDate({start_date: value, end_date: e.endDate}))
              return !isEqualDate({start_date: value, end_date: e.endDate});
            }),
            $autoDirty: true
          },
          endDate: {
            required: helpers.withMessage("El campo fecha final es requerida.", requiredIf(function(value, e) {
              return (!e.questionOne)? false: true;
            })),
            isValid: helpers.withMessage("El campo debe tener una fecha mayor a la fecha inicial.",function(value, e){
              if(!e.questionOne){
                return true;
              }

              return !isBeforeDate({start_date: value, end_date: e.startDate});
            }),
            $autoDirty: true
          },
        },
      },
    };
  },

  beforeMount() {
    this.affiliatedObjectsClass = new Affiliated();
  },

  mounted() {
  },

  components: {
    ChooseButton,
    DateComponent,
    Loading
  },

  methods: {
    changePublicChargeStartDate(value){
      this.laborInformation.publicCharge.startDate = value;
    },
    changePublicChargeEndDate(value){
      this.laborInformation.publicCharge.endDate = value;
    },
    changeFamilyPublicChargeStartDate(value){
      this.laborInformation.publicChargeFamily.startDate = value;
    },
    changeFamilyPublicChargeEndDate(value){
      this.laborInformation.publicChargeFamily.endDate = value;
    },
    touch(){
      //return this.v$.$touch();
      this.v$.$validate()
      return this.v$.$error
    },
    async validatePostalCode(postalCode) {
    
      if (postalCode == null || postalCode.length < 5 || postalCode.length > 5) {
        return false;
      }
      this.isLoading = true;

      this.affiliatedObjectsClass
        .getColonies(postalCode)
        .then((resp) => {
          const response = resp.data;
          if (!response.ERROR) {
            this.location.suburbs = response.colonias;
            this.location.state = response.estado;
            this.location.city = response.ciudad;
            this.location.municipality = response.municipio;

            this.laborInformation.address.postalCode = postalCode;
            this.laborInformation.address.suburb = "";
            this.laborInformation.address.location.state = response.estado;
            this.laborInformation.address.location.city = response.ciudad;
            this.laborInformation.address.location.municipality = response.municipio;
            return;
          }
        
          throw "postal code not found";
        })
        .catch((err) => {
          this.clearAddressInformation();
          Swal.fire({
            title: "Aviso",
            customClass: {
              container: "my-swal",
            },
            text: "Introduzca un código postal valido",
            icon: "warning",
            confirmButtonColor: "#FEB72B",
          });
        });
      this.isLoading = false;
    },
    clearAddressInformation(){
      this.location.suburbs = "";
      this.location.state = "";
      this.location.city = "";
      this.location.municipality = "";

      this.laborInformation.address.suburb = "";
      this.laborInformation.address.location.state = "";
      this.laborInformation.address.location.city = "";
      this.laborInformation.address.location.municipality = "";
    },
    clearFormWork() {
      //work
      this.laborInformation.work.workName = "";
      this.laborInformation.work.freeWorkCenterName = "";
      this.laborInformation.work.workCenter.businessLine = "";
      this.laborInformation.work.workCenter.workCenter = "";
      this.laborInformation.work.workCenter.workCenterName = "";
      this.laborInformation.work.workCenter.unionSection = "";
      this.laborInformation.work.typeOfWork = "";
    },
    clearFormAddress() {
      //address
      this.laborInformation.address.location.state = "";
      this.laborInformation.address.location.city = "";
      this.laborInformation.address.location.municipality = "";
      this.laborInformation.address.postalCode = "";
      this.laborInformation.address.suburb = "";
      this.laborInformation.address.street = "";
      this.laborInformation.address.interiorNumber = "";
      this.laborInformation.address.exteriorNumber = "";

      this.location.suburbs = [];
      this.location.state = "";
      this.location.city = "";
      this.location.municipality = "";
    },
    clearFormTelephone() {
      //telephone
      this.laborInformation.telephone.number = "";
      this.laborInformation.telephone.lada = "";
    },
    clearFormPublicCharge() {
      //public Charge
      this.laborInformation.publicCharge.questionOne = "";
      this.laborInformation.publicCharge.questionTwo = "";
      this.laborInformation.publicCharge.startDate = "";
      this.laborInformation.publicCharge.endDate = "";
    },
    clearFormFamilyPublicCharge(){
      //public Charge
      this.laborInformation.publicChargeFamily.questionOne = "";
      this.laborInformation.publicChargeFamily.questionTwo = "";
      this.laborInformation.publicChargeFamily.startDate = "";
      this.laborInformation.publicChargeFamily.endDate = "";
    },
    changeLaboralStatus(status= true) {
      this.v$.$reset(); // Limpia validaciones de inputs
      this.resetdisplayLaborInformation(); // Reinicia formularios a mostrar
      this.getCatalogBusinessActivities(); // Datos de actividad empresariales

      if (status == false) {
        this.workTypesMenu = false; // Menu de tipo de trabajo
        this.setWindowBytypeOfWork("pension");
        this.laborInformation.work.workStatus = false;
        // console.log("entro",this.workTypesMenu)
        return;
      }else if(status == null){
        this.workTypesMenu = false; // Menu de tipo de trabajo
        this.setWindowBytypeOfWork("Estudiante");
        this.laborInformation.work.workStatus = null;
        this.laborInformation.work.typeOfWork = "";
        return;
      }

      this.getCatalogUnionSections(); // Datos de seccion sindical
      this.getCatalogAffiliatedBusiness(); // Datos de empresas afiliadas

      this.workTypesMenu = true; // Menu de tipo de trabajo
      this.setWindowBytypeOfWork("Empleado"); // Establece los inputs a mostrar segun el tipo de trabajo
      this.laborInformation.work.workStatus = true;
      
      this.buttonsWorkTypeKey = Math.floor(Math.random() * (1 - 50)) + 50;
      this.laborInformation.work.typeOfWork = "Empleado";
      // console.log("entro",this.workTypesMenu)
    },
    changeWorkType(type) {
      // Detecta los cambios del tipo de trabajo
      this.v$.$reset();
      this.setWindowBytypeOfWork(type);
      this.laborInformation.work.typeOfWork = type;
    },
    changePublicCharge(status) {
      this.v$.$reset();
      // Detecta los cambios del cargo publico
      this.displayLaborInformation.publicCharge.questionTwo = status;
      this.displayLaborInformation.publicCharge.startDate = status;
      this.displayLaborInformation.publicCharge.endDate = status;

      this.clearFormPublicCharge();
      this.laborInformation.publicCharge.questionOne= status;
    },
    changeFamilyPublicCharge(status) {
      this.v$.$reset();
      // Detecta los cambios del familiar con cargo publico
      this.displayLaborInformation.publicChargeFamily.questionTwo = status;
      this.displayLaborInformation.publicChargeFamily.startDate = status;
      this.displayLaborInformation.publicChargeFamily.endDate = status;

      this.clearFormFamilyPublicCharge();
      this.laborInformation.publicChargeFamily.questionOne= status;
    },
    setWindowBytypeOfWork(type) {
      this.resetdisplayLaborInformation(); // Restablece el status de los campos en los formularios
      switch (type) {
        case "Empleado":
          this.showFormEmployee();
          this.clearFormWork();
          break;
        case "Negocio Propio":
          this.showFormOwnBusiness();
          this.clearFormWork();
          break;
        case "Trabajador de la educacion":
          this.showFormEducationWorker();
          this.clearFormWork();
          break;
        case "OTRO":
          this.showFormOther();
          break;
        case "Estudiante":
          this.showFormStudent();
          break;
        case "pension":
          this.showFormPension();
          this.clearFormPublicCharge();
          this.clearFormFamilyPublicCharge();
          this.clearFormWork();
          this.clearFormAddress();
          this.clearFormTelephone();
          break;
      }
    },
    getCatalogAffiliatedBusiness() {
      this.affiliatedObjectsClass
      .getAffiliatedBusiness(sessionStorage.getItem("login"))
      .then((resp) => {
        const response = resp.data.response;
        if (response.list_affiliated_business) {
          this.listAffiliatedBusiness = response.list_affiliated_business;
        } else {
          this.listAffiliatedBusiness = [];
        }
      })
      .catch((err) => {
        console.log(err);
      });
     
    },
    getCatalogBusinessActivities() {
      this.affiliatedObjectsClass
      .getBusinessActivities(sessionStorage.getItem("login"))
      .then((resp) => {
        let response = resp.data.response.data;
        if (response) {
          response = response.filter(activity => activity.status === 'activo');
          this.listBusinessActivities = response;
        } else {
          this.listBusinessActivities = [];
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getCatalogUnionSections() {
      this.affiliatedObjectsClass
      .getUnionSections(sessionStorage.getItem("login"))
      .then((resp) => {
        const response = resp.data.response.data;

        if (response) {
          this.listUnionSections = response;
        } else {
          this.listUnionSections = [];
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    initialdisplayLaborInformation() {
      return {
        work: {
          workName: false,
          freeWorkCenterName: false,
          workCenter: {
            businessLine: false,
            workCenter: false,
            workCenterName: false,
            unionSection: false,
          },
        },
        address: false,
        telephone: false,
        publicCharge: {
          questionOne: false,
          questionTwo: false,
        },
        publicChargeFamily: {
          questionOne: false,
          questionTwo: false,
        },
      };
    },
    resetdisplayLaborInformation() {
      this.displayLaborInformation = this.initialdisplayLaborInformation();
    },
    showFormPension() {
      // Mostrar inputs de formulario de jubilado
      this.displayLaborInformation.work.workCenter.businessLine = true; // Formulario de trabajo
      this.displayLaborInformation.publicCharge.questionOne = true; // Formulario cargo publico
      this.displayLaborInformation.publicChargeFamily.questionOne = true; // Formulario familiar con cargo publico
    },
    showFormEmployee() {
      // Mostrar inputs de formularios empleado
      this.displayLaborInformation.work.workCenter.businessLine = true; // Formulario de trabajo
      this.displayLaborInformation.work.workCenter.workCenter = true;
      this.displayLaborInformation.address = true; // Formulario de diréccion
      this.displayLaborInformation.telephone = true; // Formulario de teléfono
      this.displayLaborInformation.publicCharge.questionOne = true; // Formulario cargo publico
      this.displayLaborInformation.publicChargeFamily.questionOne = true; // Formulario familiar con cargo publico
    },
    showFormOwnBusiness() {
      // Mostrar inputs de formularios negocio propio
      this.displayLaborInformation.work.workCenter.workName = true; // Formulario de trabajo
      this.displayLaborInformation.work.workCenter.businessLine = true; // Formulario de trabajo
      this.displayLaborInformation.address = true; // Formulario de diréccion
      this.displayLaborInformation.telephone = true; // Formulario de teléfono
      this.displayLaborInformation.publicCharge.questionOne = true; // Formulario cargo publico
      this.displayLaborInformation.publicChargeFamily.questionOne = true; // Formulario familiar con cargo publico
    },
    showFormEducationWorker() {
      // Mostrar inputs de formularios trabajador de la educacion
      this.displayLaborInformation.work.freeWorkCenterName = true;
      this.displayLaborInformation.work.workCenter.unionSection = true; // Formulario de trabajo
      this.displayLaborInformation.address = true; // Formulario de diréccion
      this.displayLaborInformation.telephone = true; // Formulario de teléfono
      this.displayLaborInformation.publicCharge.questionOne = true; // Formulario cargo publico
      this.displayLaborInformation.publicChargeFamily.questionOne = true; // Formulario familiar con cargo publico
    },
    showFormOther() {
      // Mostrar inputs de formularios trabajo otro
      this.displayLaborInformation.work.workCenter.businessLine = true; // Formulario de trabajo
      this.displayLaborInformation.work.workCenter.workCenter = true;
      this.displayLaborInformation.address = true; // Formulario de diréccion
      this.displayLaborInformation.telephone = true; // Formulario de teléfono
      this.displayLaborInformation.publicCharge.questionOne = true; // Formulario cargo publico
      this.displayLaborInformation.publicChargeFamily.questionOne = true; // Formulario familiar con cargo publico
    },
    showFormStudent() {
      this.displayLaborInformation.publicCharge.questionOne = true; // Formulario cargo publico
      this.displayLaborInformation.publicChargeFamily.questionOne = true; // Formulario familiar con cargo publico
    },
  },

  watch: {
    "laborInformation.work.workCenter.workCenter": function(value) {
      if (value != "OTRO") {
        this.displayLaborInformation.work.workCenter.workCenterName = false;
        return false;
      }

      this.laborInformation.work.workCenter.workCenterName = "";
      //this.laborInformation.work.workCenter.businessLine = value;
      this.displayLaborInformation.work.workCenter.workCenterName = true;
    },
    laborInformation: {
      deep: true,
      handler: function(value){
        this.$emit('labor-information-data', value);
      }
    }
  },
}; 
</script>
<style scoped>
select {
  font-family: "HelveticaNeueLight";
  color: #3b3b3b;
  font-size: 16px;
}
.text-dark {
  color: #650a35;
}
.bg-dark {
  background: #650a35;
}
</style>
