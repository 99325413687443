<template>
  <div>
    <div class="flex mb-6">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <img
            src="@/assets/CapturaAsistida/triangle.svg"
            style="width:30px;"
            alt=""
          />
        </div>
      </div>
      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title">Datos de ahorro</label>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap mb-6 md:ml-10" v-if="rulesLoaded">
      <div class="mt-5">
        <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-10">
          <div v-if="savingData.length==0">
           <span class="errors"> FAVOR DE CAPTURAR LOS DATOS DE INFORMACIÓN LABORAL</span>
          </div>
            <div v-if="savingData.length>0">
                <ChooseButton 
                :buttons="savingData"
                v-on:choose-button="changePaymentMethod($event)"
                label="Método de pago:" />
                  <div class="content-text" style="float:left">
                    <template v-if="v$.saving_registration_attributes.payment_method.$dirty">
                      <div class="input-errors" v-for="error of v$.saving_registration_attributes.payment_method.$errors" :key="error.$uid">
                        <div class="errors">{{ error.$message }}</div>
                    </div>
                  </template>
                </div>
            </div>

            <div v-if="saving_registration_attributes.payment_method=='CargoTarjeta'">
              <div class="form-group">
                  <p class="label-input">Tipo de cuenta:</p>
                  <div class="input-group">
                    <input type="radio" name="rdbStatus" value="Cuenta Clabe" id="account_type_key_account"
                    v-on:change="changeAccountType($event.target.value)"/> <label for="account_type_key_account" class="ml-2" > Cuenta Clabe</label>
                    <input type="radio" name="rdbStatus" value="Numero de tarjeta" id="account_type_card_number"
                    v-on:change="changeAccountType($event.target.value)" style="margin-left:20px;" > <label for="account_type_card_number" class="ml-2"> Número de tarjeta</label>     
                  </div>
                </div>

              <div class="content-text" style="float:left">
                <template v-if="v$.bank_account_attributes[0].account_type.$dirty">
                      <div class="input-errors" v-for="error of v$.bank_account_attributes[0].account_type.$errors" :key="error.$uid">
                        <div class="errors">{{ error.$message }}</div>
                    </div>
                  </template>
              </div>

            </div>

              <div v-if="saving_registration_attributes.payment_method=='CargoTarjeta'">
              <div class="form-group">
                  <label class="label-input">Banco:</label>
                    <div class="input-group" style="width:100%">
                      <select lang="en" translate="no" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor mt-2" ref="stateSelected"
                      :class="{'invalid-form':v$.bank_account_attributes[0].bank_name.$error, 'valid-form':!v$.bank_account_attributes[0].bank_name.$invalid}" 
                       v-model.trim="v$.bank_account_attributes[0].bank_name.$model">
                          <option value="" selected disabled >Seleccionar una opcion</option>
                          <option :value="item.name" v-for="(item,index) in list_banks" :key="item.id" :id="'option_bank_'+index" >{{item.name}}</option>
                      </select>
                  </div>
              </div>
               <div class="content-text" style="float:left;margin-top:10px"> 
                   <template v-if="v$.bank_account_attributes[0].bank_name.$dirty">
                      <div class="input-errors" v-for="error of v$.bank_account_attributes[0].bank_name.$errors" :key="error.$uid">
                        <div class="errors">{{ error.$message }}</div>
                    </div>
                  </template>
               </div>
            </div>

            <div v-if="bank_account_attributes[0].account_type=='Cuenta Clabe' && saving_registration_attributes.payment_method=='CargoTarjeta'">
              <p class="label-input">Cuenta Clabe:</p>
              <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" id="input_card_number_saving_data"  v-maska="'##################'" 
              style="color:#3B3B3B;" v-model.trim="v$.bank_account_attributes[0].account_number.$model" @keyup="v$.bank_account_attributes[0].account_number.$validate()" 
              @blur="v$.bank_account_attributes[0].account_number.$validate()"
              :class="{'invalid-form':v$.bank_account_attributes[0].account_number.$error, 'valid-form':!v$.bank_account_attributes[0].account_number.$invalid}" >

             <div class="content-text" style="float:left;margin-top:10px">
                    <div v-if="v$.bank_account_attributes[0].account_number.$dirty">
                      <div class="input-errors" v-for="error of v$.bank_account_attributes[0].account_number.$errors" :key="error.$uid">
                        <span class="errors">{{ error.$message }}</span>
                    </div>
                  </div>
              </div>
            </div>

            <div v-if="bank_account_attributes[0].account_type=='Numero de tarjeta' && saving_registration_attributes.payment_method=='CargoTarjeta'" >
                  <p class="label-input">Número de tarjeta:</p>
                  <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor " id="input_card_number_saving_data" @keyup="v$.bank_account_attributes[0].account_number.$validate()"
                  @blur="v$.bank_account_attributes[0].account_number.$validate()"
                   v-model.trim="v$.bank_account_attributes[0].account_number.$model"  v-maska="bank_account_attributes[0].bank_name=='AMERICAN EXPRESS'?'####-######-#####':'####-####-####-####'"   style="color:#3B3B3B;"
                   :class="{'invalid-form':v$.bank_account_attributes[0].account_number.$error, 'valid-form':!v$.bank_account_attributes[0].account_number.$invalid}" >

                  <div class="content-text" style="float:left;margin-top:10px">
                    <div v-if="v$.bank_account_attributes[0].account_number.$dirty">
                      <div class="input-errors" v-for="error of v$.bank_account_attributes[0].account_number.$errors" :key="error.$uid">
                        <span class="errors">{{ error.$message }}</span>
                    </div>
                  </div>
                 </div>
            </div>


            <div v-if="bank_account_attributes[0].account_type=='Numero de tarjeta' && saving_registration_attributes.payment_method=='CargoTarjeta'"  >
                  <p class="label-input">Fecha Vencimiento:</p>
                    <div class="shadow-md form-group rounded-lg">
                      <div style="display:flex;width: 100%;" >
                        <div style="width: 100%;">
                          <select class="rounded-lg w-full px-3 py-3 clear-focus border-2" style="border-right:none;border-top-right-radius:0;border-bottom-right-radius:0" 
                          id="input_expiration_date_saving_data_month"  v-model.trim="v$.bank_account_attributes[0].expiration_month.$model"
                          :class="{'invalid-form':v$.bank_account_attributes[0].expiration_month.$error, 'valid-form':!v$.bank_account_attributes[0].expiration_month.$invalid}">
                            <option value="" selected="true" disabled="disabled">MM</option>
                            <option :value="month" v-for="(month, i) in list_months" :key="i" :id="'option_month_'+i">{{month}}</option>
                        </select>
                      </div>
                        <div style="width: 100%;">
                          <select  class="rounded-lg w-full px-3 py-3 clear-focus border-2" style="border-left:none;border-top-left-radius:0;border-bottom-left-radius:0 " 
                          id="input_expiration_date_saving_data_year"  v-model.trim="v$.bank_account_attributes[0].expiration_year.$model"
                          :class="{'invalid-form':v$.bank_account_attributes[0].expiration_year.$error, 'valid-form':!v$.bank_account_attributes[0].expiration_year.$invalid}"
                          v-on:change="getMonths($event.target.value)">
                            <option value="" selected="true" disabled="disabled">AA</option>
                            <option :value="year" v-for="(year, i) in list_years" :key="i" :id="'option_year_'+i">{{year}}</option>
                          </select>
                        </div>
                    </div>
                  </div>
                  

               <div class="content-text" style="float:left;margin-top:10px">
                    <div v-if="v$.bank_account_attributes[0].expiration_month.$dirty">
                      <div class="input-errors" v-for="error of v$.bank_account_attributes[0].expiration_month.$errors" :key="error.$uid">
                        <span class="errors">{{ error.$message }}</span>
                    </div>
                  </div>
              </div>

               <div class="content-text" style="float:left;margin-top:10px">
                    <div v-if="v$.bank_account_attributes[0].expiration_year.$dirty">
                      <div class="input-errors" v-for="error of v$.bank_account_attributes[0].expiration_year.$errors" :key="error.$uid">
                        <span class="errors">{{ error.$message }}</span>
                    </div>
                  </div>
               </div>
              </div>

            <div>
                <ChooseButton 
                    :buttons="savingperiod"
                    v-on:choose-button="selectsavingPeriod($event)"
                    label="Periodo de ahorro:" />

                  <div class="content-text" style="float:left;margin-top:10px">
                    <div v-if="v$.saving_registration_attributes.payment_frecuency.$dirty">
                      <div class="input-errors" v-for="error of v$.saving_registration_attributes.payment_frecuency.$errors" :key="error.$uid">
                        <span class="errors">{{ error.$message }}</span>
                    </div>
                  </div>
               </div>

            </div>

            <div>
                <p id="label_savings_amount" class="label-input" >Importe de ahorro: </p>
                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor mt-2" id="input_amount_saving_data"   v-model.trim="v$.saving_registration_attributes.amount.$model" style="color:#3B3B3B;" v-money="money"
                :class="{'invalid-form':v$.saving_registration_attributes.amount.$error && importFlag, 'valid-form':!v$.saving_registration_attributes.amount.$invalid && importFlag}" @blur="importFlag=true">

                
               <div class="content-text" style="float:left;margin-top:10px">
                    <div v-if="v$.saving_registration_attributes.amount.$dirty && importFlag">
                      <div class="input-errors" v-for="error of v$.saving_registration_attributes.amount.$errors" :key="error.$uid">
                        <span class="errors">{{ error.$message }}</span>
                    </div>
                  </div>
               </div>
            </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChooseButton from "@/components/Forms/Buttons/ChooseButton.vue";
import { VMoney } from "v-money";
import useVuelidate from "@vuelidate/core";
import {required, requiredIf,between,minLength} from 'vuelidate/lib/validators'
import { months } from 'moment';
import { helpers } from '@vuelidate/validators';
import('@/assets/css/stylesheet.css')
import('@/assets/css/referredperson.css')
export default {
  name: "SavingData",
  components: {
    ChooseButton,
  },
  directives: { money: VMoney },
  setup(){
    return{
      v$:useVuelidate(),
    }
  },
  data() {
    return {
      ccapi:process.env.VUE_APP_CCAPI,
      list_banks:[],
      accountType:{},
      savingData: [
          { id: "button_payroll_saving_data ", value: "ViaNomina", text: "Vía Nómina" },
          { id: "button_card_charge_saving_data", value: "CargoTarjeta", text: "Cargo Tarjeta"}
      ],
      savingperiod:[
        { id: "button_fortnightly_saving_data", value: "Quincenal", text: "Quincenal"},
        { id: "button_montly_saving_data", value: "Mensual", text: "Mensual" },
      ],
      typesavingPeriod:"N/A",
      list_years:[],
      list_months:[],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      saving_registration_attributes:{
        amount: "",
        status: "",
        date_of_end: "",
        date_of_start: "",
        payment_method: "",
        payment_frecuency: "",
        number_of_contribution:""
      },
      bank_account_attributes: {
        0: {
          bank_name: "",
          account_type: "",
          account_number: "",
          expiration_year: "",
          expiration_month: ""
        }
      },
      importFlag:false
    };
  },
   props: {
      rulesValidations: {
        type: Object,
        default: () => {}
      },
      rulesLoaded: {
        type: Boolean,
        default: false
      },
  },
  validations(){
    return{
      saving_registration_attributes:{
        amount:{
          required:helpers.withMessage(()=> {
            return "El campo importe "+this.saving_registration_attributes.payment_frecuency+" de ahorro es requerido"}
          ,function (){
            return this.rulesValidations.savingData.amount.required && (this.fomartNumber(this.saving_registration_attributes.amount)>=50)
          }),
          awfwaf:helpers.withMessage(" El campo importe "+this.saving_registration_attributes.payment_frecuency+" tiene que ser en multiplos de $50",function () {
            return (this.fomartNumber(this.saving_registration_attributes.amount)%this.rulesValidations.savingData.amount.multiplesof)==0 && this.fomartNumber(this.saving_registration_attributes.amount)>0?true:false;
          }),
          minValue: helpers.withMessage(()=> {
            return "El campo importe "+this.saving_registration_attributes.payment_frecuency+" de ahorro debe ser mayor o igual a 50"
          }, function() {
            return (this.fomartNumber(this.saving_registration_attributes.amount)>=50);
          }),
        },
        payment_method:{
          required:helpers.withMessage("Seleccione un método de pago.",requiredIf(function(){return this.rulesValidations.savingData.payment_method.required})),
        },
        payment_frecuency:{
          required:helpers.withMessage("Seleccione un periodo de ahorro.",requiredIf(function(){return this.rulesValidations.savingData.payment_frecuency.required})),
        },
      },
      bank_account_attributes:{
        0:{
          bank_name:{
              required:helpers.withMessage("El campo banco es requerido.",requiredIf(function(){
                return this.saving_registration_attributes.payment_method=="CargoTarjeta";
              }))
          },
          account_type:{
            required: helpers.withMessage("Seleccione el tipo de cuenta.",requiredIf(function(){
              return this.saving_registration_attributes.payment_method=="CargoTarjeta";
            }))
          },
          account_number:{
            required:helpers.withMessage("El campo cuenta clabe es requerido",requiredIf(function(){
              return this.saving_registration_attributes.payment_method=="CargoTarjeta";
            })), 
            minValue:
            helpers.withMessage(   
              ()=>{
                if(this.saving_registration_attributes.payment_method=="CargoTarjeta" &&  this.bank_account_attributes[0].account_type=='Numero de tarjeta' && this.bank_account_attributes[0].bank_name=="AMERICAN EXPRESS"){
                  return "El campo debe contener mínimo 15 caracteres."
                }
              else{
                if(this.saving_registration_attributes.payment_method=="CargoTarjeta" &&  this.bank_account_attributes[0].account_type=='Cuenta Clabe'){
                  return "El campo debe contener mínimo 18 caracteres.";
                }
                  else{
                    return "El campo debe contener mínimo 16 caracteres.";
                  }
                } 
              },
              ()=>{
                 if(this.saving_registration_attributes.payment_method=="CargoTarjeta" &&  this.bank_account_attributes[0].account_type=='Numero de tarjeta' && this.bank_account_attributes[0].bank_name=="AMERICAN EXPRESS"){
                  return this.bank_account_attributes[0].account_number.length==17;
                }
                else{
                  if(this.saving_registration_attributes.payment_method=="CargoTarjeta" &&  this.bank_account_attributes[0].account_type=='Cuenta Clabe'){
                    return this.bank_account_attributes[0].account_number.length==18;
                  }
                  else{
                    if(this.saving_registration_attributes.payment_method=="CargoTarjeta" &&  this.bank_account_attributes[0].account_type=='Numero de tarjeta'){
                      return this.bank_account_attributes[0].account_number.length==19;
                    }else{
                      return true;
                    }
                  }
                } 
              }
            ),      
          },
          expiration_year:{
             required:helpers.withMessage("El campo año de vencimiento es requerido.",requiredIf(function(){
                return this.saving_registration_attributes.payment_method=="CargoTarjeta" && this.bank_account_attributes[0].account_type=='Numero de tarjeta'  ;
              }))
          },
          expiration_month:{
             required:helpers.withMessage("El campo mes de vencimiento es requerido.",requiredIf(function(){
                return this.saving_registration_attributes.payment_method =="CargoTarjeta" && this.bank_account_attributes[0].account_type=='Numero de tarjeta';
              }))
          }
        },
      }
    }
  },
  watch:{
    saving_registration_attributes:{
      handler(data){
        // console.log("saving:", data)
        this.$emit("saving_registration_attributes",data)
      },
      deep:true
    },
    bank_account_attributes:{
      handler(data){
        // console.log("bank:", data)
        this.$emit("bank_account_attributes",data)
      },
      deep:true
    },
  },
  mounted(){
    this.getYearsCard();
    this.getMonths(parseInt((new Date().getMonth()+1).toString().substring(2, 4)));
    this.getBanks();
    //Validacion de tipo de vista para los datos a mostrar 
  },
  methods:{
    statusEmplyment(valueRefs){
      this.savingData = [];
      if(valueRefs.laboral_status == "ACTIVO" && valueRefs.principal_occupation == "Empleado" && valueRefs.workCenter == "GRUPO CABSA"  ||  valueRefs.principal_occupation == "Trabajador de la educacion" ){
        this.savingData = [
          { id: "button_payroll_saving_data ", value: "ViaNomina", text: "Vía Nómina" },
          { id: "button_card_charge_saving_data", value: "CargoTarjeta", text: "Cargo Tarjeta"},
        ];
      }

      if(valueRefs.laboral_status == "ACTIVO" && (valueRefs.principal_occupation == "Negocio Propio" || valueRefs.workCenter == "OTRO") ){
        this.savingData = [
          {id: "button_card_charge_saving_data", value: "CargoTarjeta", text: "Cargo Tarjeta"},
        ]
      }

      if(valueRefs.laboral_status=="JUBILADO" || valueRefs.laboral_status=="ESTUDIANTE"){
        this.savingData = [
          {id: "button_card_charge_saving_data", value: "CargoTarjeta", text: "Cargo Tarjeta"},
        ]
      }
    },
    changePaymentMethod(value){
      this.saving_registration_attributes.payment_method = value;
      this.bank_account_attributes[0].account_type = "";
      this.clearControls();
    },
    changeAccountType(value){
      this.bank_account_attributes[0].account_type = value;
      this.clearControls();
    },
    clearControls(){
      this.bank_account_attributes[0].account_number = "";
      this.bank_account_attributes[0].expiration_month = "";
      this.bank_account_attributes[0].expiration_year = "";
      this.bank_account_attributes[0].bank_name = "";
    },
    selectsavingPeriod(value){
      this.saving_registration_attributes.payment_frecuency = value;
    },
    touch(){
      this.importFlag = true;
      this.v$.$validate()
      return this.v$.$error 
    },
    getYearsCard(){
      var years = [];
      var startYear = new Date().getFullYear() - 1;
      var lastYear = new Date().getFullYear() + 5;
        
      while (parseInt(startYear) < parseInt(lastYear) ) {
          startYear++;
          var year = startYear;
          years.push(parseInt(year.toString().substring(2, 4)));
      }
      this.list_years = years;
      return years;
    },
    getMonths(date){
      this.list_months = [];
      date = parseInt(date);
      let month = new Date().getMonth()+1;
      let months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      if(date === this.list_years[0]){
        months.forEach(element => {
          if(element >= month){
            this.list_months.push(element);
          }
        });
      }else{
        this.list_months = months;
      }
    },
      async getBanks() {
        await this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_all_banks`, {
          params:{
            token_auth: sessionStorage.getItem("login")
          }
          }, { 'Content-Type': 'application/json' })
              .then(response => {
               let banksResponse= response.data.response.lista_bancos;
               this.list_banks =banksResponse.sort(function(a,b){
                 if(a.name>b.name){
                   return 1
                 } if (a.name < b.name) {
                    return -1;
                  } 
                  return 0;
               })
            }).catch(error => {
          });
      },
    fomartNumber(value){
      var regex = /^[,.]+$/;
      if (regex.test(value) != true) {
        value = value.replace(
          /[^0-9.]+/g,
          ""
        );
      }
      return value;
    },
  }
};
</script>


<style scoped>
.clear-focus:focus{
 border: none;
}
</style>