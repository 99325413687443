<template>
  <div>
    <div class="flex">
      <div style="display:table;">
        <div style="display:table-cell;vertical-align:middle;">
          <img src="@/assets/CapturaAsistida/triangle.svg" style="width:30px;" alt="">
        </div>
      </div>
      <div style="display:table;margin-left:10px;">
        <div style="display:table-cell;vertical-align:middle;">
          <label class="sub-title">Carga de Documentos</label>
        </div>
      </div>
    </div>
    <div class="md:ml-10 mt-10">
      <table class="upload-document">
        <thead>
          <tr>
            <th>Documento</th>
            <th>Estatus</th>
            <th>Nombre del archivo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in documentsRequired" :key="i">
            <td style="font-size:16px;font-family:Helvetica;font-weight: 400;vertical-align: top;">
              {{item.name}} <label style="font-weight: 900;" v-if="item.optional == false">*</label>
              <p v-show="item.name == 'CURP'" style="font-size:12px;font-family:Roboto;font-weight: 300;"><a href="https://www.gob.mx/curp/" target="_blank" class="text-blue-600 visited:text-purple-600 target:shadow-lg">Descargar aquí.</a> </p>
              <p v-show="item.name == 'Constancia de situación Fiscal'" style="font-size:12px;font-family:Roboto;font-weight: 300;"><a href="https://www.sat.gob.mx/aplicacion/login/53027/genera-tu-constancia-de-situacion-fiscal" target="_blank" class="text-blue-600 visited:text-purple-600 target:shadow-lg">Descargar aquí.</a> </p>
            </td>
            <td style="height:40px;vertical-align: top;">
              <Check v-if="item.documentAttached" style="margin:0px;" />
            </td>
            <td style="" v-if="item.max_documents_required == 1">
              <div class="titles">
                <i style="width:100%;">
                  {{
                    documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation).length > 0 ?
                    documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation)[0].name :
                    ""
                  }}</i>
              </div>
            </td>
            <td style="" v-if="item.max_documents_required == 1">
              <div v-show="item.name == 'Recibo pago parte social'" style="display:flex;justify-content:right;margin-bottom:10px;">
                <div style="ori">
                  <p class="subtitle" style="margin-top:5px;margin-bottom:0px;">
                    Fecha de depósito:
                  </p>
                  <date-picker
                    simple
                    color="#810042"
                    placeholder="Fecha de depósito"
                    locale="es"
                    input-format="DD/MM/YYYY"
                    @change="selectDate"
                    :localeConfig="{
                      es: {
                        displayFormat: 'DD/MM/YYYY',
                          lang: {
                            year: 'Año',
                            month: 'Mes',
                            day: 'Día',
                            label: '',
                            submit: 'Seleccionar',
                            cancel: 'Cancelar',
                            now: 'Hoy',
                            nextMonth: 'Siguiente',
                            prevMonth: 'Anterior',
                          },
                        }
                    }" />
                </div>
              </div>
              <input type="button" 
                :id="`upload_file_button_${item.abbreviation}`"
                v-show="documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation).length <= 0" 
                class="btn-load-document-desktop" value="Cargar archivo" 
                v-on:click="getFile(`input_file_upload_${item.abbreviation}`)" />
              <button v-on:click="getFile(`input_file_upload_${item.abbreviation}`)" 
                :id="`upload_file_button_mobile_${item.abbreviation}`"
                v-show="documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation).length <= 0" 
                class="btn-load-mobile" 
                style="text-decoration:none;">
                <img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;">
              </button>
              <input type="button" 
                :id="`delete_file_button_${item.abbreviation}`"
                v-show="documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation).length > 0" 
                class="btn-delete-document btn-delete-desktop" 
                value="Eliminar" 
                v-on:click="deleteDocument(
                  item.abbreviation, 
                  documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation).length > 0 ?
                  documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation)[0].name :
                  '')" />
              <button
                :id="`delete_file_button_mobile_${item.abbreviation}`"
                v-show="documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation).length > 0" 
                v-on:click="deleteDocument(
                  item.abbreviation, 
                  documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation).length > 0 ?
                  documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation)[0].name :
                  '')"
                class="btn-delete-mobile">
                <img src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;">
              </button>
              <div style='height: 0px;width:0px; overflow:hidden;'>
                <input :id="`input_file_upload_${item.abbreviation}`" 
                  type="file" 
                  :accept="item.allowedExtensions.split('|').join(',')"
                  @change="loadDocument($event, item.abbreviation, item.max_documents_required, item.allowedExtensions.split('|'))"/>
              </div>
            </td>
            <td style="vertical-align:top;" colspan="2" v-if="item.max_documents_required > 1">
              <table class="table-scroll small-first-col subtable">
                <thead>
                </thead>
                <tbody class="body-half-screen">
                  <tr v-for="(item2, i) in documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation)" :key="item2.id">
                    <td class="tdTable" style="">
                      <div class="grid grid-cols-1">
                        <div class="titles">
                          <i>{{item2.name}}</i>
                        </div>
                        <div>
                        <button 
                          :id="`delete_file_button_mobile_${item.abbreviation}_${i}`"
                          v-on:click="deleteDocument(item2.abbreviation, item2.name)"
                          class="btn-delete-mobile" 
                          style="text-decoration:none;">
                          <img height="40" width="40" src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;">
                        </button>
                        </div>
                      </div>
                    </td>
                    <td class="tdButton" style="padding:5px 0px 5px 0px;">
                      <input type="button" 
                        :id="`delete_file_button_${item.abbreviation}_${i}`"
                        class="btn-delete-document btn-delete-desktop" 
                        value="Eliminar" 
                        v-on:click="deleteDocument(item2.abbreviation, item2.name)" 
                        style="margin-right: 10px !important;"/>
                    </td>
                  </tr>
                  <input type="button" 
                    :id="`upload_file_button_${item.abbreviation}`"
                    class="btn-load-document-desktop"
                    :value="
                    documentObjectClass.listDocuments.filter(d => d.abbreviation == item.abbreviation).length > 0 
                    ? 'Cargar más archivos'
                    : 'Cargar archivo'" 
                    v-on:click="getFile(`input_file_upload_${item.abbreviation}`)"
                    style="float:right !important;margin-right: 10px !important;" />
                  <button 
                    :id="`upload_file_button_mobile_${item.abbreviation}`"
                    v-on:click="getFile(`input_file_upload_${item.abbreviation}`)"
                    class="btn-load-mobile" 
                    style="text-decoration:none;">
                    <img height="40" 
                      width="40" 
                      src="@/assets/Cuenta/cargar.svg" 
                      style="width:28px;margin-left:1px;">
                  </button>
                  <div style='height:0px;width:0px; overflow:hidden;'>
                    <input :id="`input_file_upload_${item.abbreviation}`" 
                      type="file" 
                      multiple="true" 
                      :accept="item.allowedExtensions.split('|').join(',')"
                      @change="loadDocument($event, item.abbreviation, item.max_documents_required, item.allowedExtensions.split('|'))" 
                      style="float:right" />
                  </div>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Check from '@/components/Loading/VueCheck.vue'
import Swal from "sweetalert2";
import SwalAlertHelper from '@/helpers/swal-alert'
import moment from "moment";

import Document from '@/classes/Document'
export default {
  data() {
    return {
      documentObjectClass: null,
      paymentDate: ""
    }
  },
  props: {
    documentsRequired: {
      type: Array,
      default: () => [],
      required: true
    },
    maxSizeBytesToUpload: {
      type: Number,
      default: 16777216
    },
    maxSizeMegaBytesToUpload: {
      type: Number,
      default: 16
    },
    displayStatusMessage: {
      type: Boolean,
      default: true
    }
  },
  name: 'UploadMultipleFilesComponent',
  components: {
    Check
  },
  mounted() {
    this.documentObjectClass = new Document();
  },
  methods: {
    getFile(selector) {
      if(selector.split("_")[3] == "RPPSOCIAL" && this.paymentDate == ""){
        Swal.fire({
          title: "Aviso",
          text:
            "Favor de seleccionar una fecha de depósito.",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        return;
      }
      this.documentObjectClass.getFileByIdSelector(selector);
    },
    loadDocument(event, type, maxDocumentsRequired=1, documentsAllowed) {
      const file = event.target.files;
      const fileAttributes = this.documentObjectClass.getDocumentAttributes(file);

      if (this.documentObjectClass.exceedTheMaximunSize(this.maxSizeBytesToUpload, fileAttributes.size)) {
        this.showExceedTheMaximunSizeMessage();
        return;
      }

      const isAllowed = this.documentObjectClass.documentExtensionIsAllowed(documentsAllowed, fileAttributes.completeExtension);

      if (!isAllowed) {
        this.isLoading = false;
        this.showExtensionIsNotAllowedMessage(documentsAllowed);
        return;
      }
        
      if(this.documentObjectClass.listDocuments.length > 0){
        if (!this.documentObjectClass.documentReachItsLimit(maxDocumentsRequired, type)) {
          if (this.documentObjectClass.fileHasAlreadyBeenUploaded(fileAttributes.name)) {
            this.isLoading = false;
            this.showFileDuplicatedMessage();
            return;
          }
        } else {
          this.showNumberFilesAllowedToAttachMessage(maxDocumentsRequired);
          return;
        }
      }

      this.documentObjectClass.serialize(file[0]).then(base64Response => {
        const newFile = this.documentObjectClass.addDocument(base64Response, fileAttributes.extension, fileAttributes.name, type);
        this.changeValueChecks(true, type);
        
        if(this.displayStatusMessage){
          this.showLoadedDocumentSuccessMessage(fileAttributes.name);
        }
        
        this.$emit('upload-file', {
          files: this.documentObjectClass.listDocuments,
          newFile: newFile,
          documentsUploaded: this.documentsUploaded(),
          enrollmentFeePaymentDate: this.paymentDate
        });
      }).catch(error => {
        console.error(error);
      });
    },
    documentsUploaded() {
      const optionalDocuments = this.documentsRequired.filter(d => d.optional == false);
      const documentsUploades = optionalDocuments.filter(o => {
        return (o.abbreviation == 'CI')? true: !o.documentAttached;
      }).length > 0 ? false : true;
      this.$forceUpdate();
      return documentsUploades;
    },
    deleteDocument(type, name) {
      console.log(`type: ${type} name: ${name}`);
      Swal.fire({
        title: "Aviso",
        text: '¿Desea eliminar el siguiente documento? ',
        html: `<p style="font-size:14px;font-family:Helvetica;font-weight:400;">¿Desea eliminar el siguiente documento?<br/>
                <span style="font-weight: bold; color:red">${name}</span>
                </p>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#FEB72B',
        cancelButtonColor: '#FEB72B',
        confirmButtonText: "SI",
        cancelButtonText: "NO"
      })
      .then((result) => {
        if (result.value) {
          const currentFiles = this.documentObjectClass.deleteDocument(name, type);
          this.changeValueChecks(false, type);
          this.$emit('deleted-file', {
            files: currentFiles,
            documentsUploaded: this.documentsUploaded()
          });
          this.showDocumentSuccesfullyDeletedMessage();
        }
      });
      SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_delete_file');
      SwalAlertHelper.setIdAttributeToButtons('button_delete_file_yes', 'button_delete_file_no');
    },
    showExtensionIsNotAllowedMessage(documentsAllowed) {
      Swal.fire({
        title: "Aviso",
        html: `<p style="font-family:Roboto;font-size:15px;">El tipo de archivo seleccionado no está permitido<br/><br/>
                  Archivos permitidos: <br/>
                <span style="font-weight: 700;">${documentsAllowed.join(", ")}</span>
                </p>`,
        text: "El tipo de archivo seleccionado no está permitido",
        icon: "error",
      });
      SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_unsupported_file_type');
    },
    showDocumentSuccesfullyDeletedMessage() {
      Swal.fire({
        html: `<p style="font-size:14px;font-family:Helvetica;font-weight:400;">Documento Eliminado Correctamente</p>`,
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        icon: "success",
        confirmButtonColor: '#FEB72B',
      });
      SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_successful_deleted');
    },
    showExceedTheMaximunSizeMessage() {
      Swal.fire({
        title: "Aviso",
        html: `<p style="font-family:Helvetica;font-size:15px;">El peso del archivo sobrepasa 
                  el limite establecido de <span style="font-weight: 700;">${this.maxSizeMegaBytesToUpload} MB</span>
                </p>`,
        icon: "error",
      });
      SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_unsupported_file_size');
    },
    showNumberFilesAllowedToAttachMessage(maxDocumentsRequired) {
      Swal.fire({
        title: "Aviso",
        text: `Solo puede subir ${maxDocumentsRequired} documentos`,
        icon: "error",
      });
    },
    showFileDuplicatedMessage() {
      Swal.fire({
        title: "Aviso",
        text: "El archivo seleccionado ya ha sido adjuntado",
        icon: "error",
      });
      SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_fileduplicate', '');
    },
    showLoadedDocumentSuccessMessage(documentName) {
      Swal.fire({
        text: "Solicitud de prestamo guardada correctamente",
        html: `<p style="font-size:14px;font-family:Helvetica;font-weight:400;">El documento: <span style="font-weight:500;">${documentName}</span> se ha adjuntado correctamente.</p>`,
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        icon: "success",
        confirmButtonColor: '#FEB72B',
      });
      SwalAlertHelper.setIdAttributeToModalWindow('pop_upmodal_successful_upload');
      SwalAlertHelper.setIdAttributeToButtons('button_ok_succes_file', '');
    },
    changeValueChecks(value, type) {
      let index = -1;
      if (!value) {
        index = this.documentObjectClass.listDocuments.findIndex(b => b.abbreviation === type);
        value = index != -1 ? true : value
      }

      let indexDocumentValidations = this.documentsRequired.findIndex(b => b.abbreviation === type);

      if (indexDocumentValidations != -1) {
        this.documentsRequired[indexDocumentValidations].documentAttached = value
      }
    },
    selectDate(event){
      if((moment(event._d).format("YYYY-MM-DD")) > (moment().format("YYYY-MM-DD"))){
        Swal.fire({
          title: "Aviso",
          text:
            "La fecha de depósito tiene que ser menor o igual a la actual.",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.paymentDate = ""
        return;
      }
      else{
        this.paymentDate = moment(event._d).format("DD/MM/YYYY")
      }
    }
  }
}
</script>

<style scoped>
.btn-load-document-desktop {
  text-decoration:none;
  font-family:Helvetica;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;
  border-radius:3px;
  width: 12em;
  outline:none;
  float:right;
  margin-right: 15px;
  visibility: visible;
}

.upload-document {
  border:1px solid #D2D2D2;
  border-radius: 1px !important;
  @apply shadow-md;
}

.upload-document tr td {
  border-bottom:1px solid #D2D2D2;
}

.subtable tr td {
  border-bottom: none;
}

.btn-load-mobile {
  text-decoration:none;
  visibility: hidden;
}

.btn-delete-document {
  text-decoration:none;
  font-family:Helvetica;
  font-size: 13px;
  color: red;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:red;
  border-width: 1px;
  border-radius:3px;
  width: 12em;
  outline:none;
  float:right;
  margin-right:15px;
}

.btn-delete-mobile {
  text-decoration:none;
  visibility: hidden;
}

.tdButton {
  display:block;
}

.titles {
  font-size:13px;
  font-family:Helvetica;
  font-weight: 400;
  word-wrap: break-word;
}

.btn-delete-desktop {
  visibility: visible;
}

.subtable {
  width: 100%;
}

.tdTable {
  height: 40px;
  padding-left: 0 !important;
}

.body-half-screen{
  height: auto;
  max-height: 24vh;
}

.table-scroll{
  width:100%;
  /* display: block; */
  empty-cells: show;
  /* Decoration */
  border-spacing: 0;
}

.table-scroll thead{
  position:relative;
  display: block;
  width:100%;
  overflow-y: scroll;
}

.table-scroll tbody{
  /* Position */
  display: block; position:relative;
  width:100%; overflow-y:scroll;
  /* Decoration */
}

.table-scroll tr{
  width: 100%;
  display:flex;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #8E8E8E; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8E8E8E; 
}

.table-scroll td,.table-scroll th{
  /* flex-basis:100%; */
  display: block;
  padding: 1rem;
  text-align:left;
}

.table-scroll.small-first-col td:first-child,
.table-scroll.small-first-col th:first-child{
  flex-basis:20%;
  flex-grow:1;
}

table.upload-document,table.rwd_auto {width:100%;margin:0 0 50px 0;}
  .upload-document th,.rwd_auto th {background:#fff;padding:5px;text-align:left;}
  .upload-document td,.rwd_auto td {padding:5px;text-align:left}
  .upload-document tr:last-child td, .rwd_auto tr:last-child td{border:0;}
  .upload-document thead tr {border-bottom:1px solid #ccc;color:#3B3B3B;font-size:16px !important;font-family:Helvetica !important;font-weight: 400 !important;}
  .upload-document tbody td {color:#3B3B3B;}

@media only screen and (max-width: 1024px)  
{
  table.upload-document, .upload-document thead, .upload-document tbody, .upload-document th, .upload-document td, .upload-document tr { display: block; }
  .upload-document thead tr { position: absolute;top: -9999px;left: -9999px; }
  .upload-document tr { border-bottom: 1px solid #ccc; }
  .upload-document td { position: relative;padding-left: 40%;text-align:left; }
  .upload-document td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}
  .upload-document td:nth-of-type(1):before { 
    content: "Documento:"; 
    color: #3B3B3B;
    font-family:Helvetica;
    font-weight: 700;
  }
  .upload-document td:nth-of-type(2):before { 
    content: "Estatus:"; 
    color: #3B3B3B;
    font-family:Helvetica;
    font-weight: 700;
  }
  .upload-document td:nth-of-type(3):before { 
    content: "Nombre:"; 
    color: #3B3B3B;
    font-family:Helvetica;
    font-weight: 700;
  }
  .upload-document td:nth-of-type(4):before { content: ""; }
  .descarto {display:none;}
  .fontsize {font-size:10px;}
}

@media only screen and (max-width: 1024px)  
{
  .subtable, .subtable thead, .subtable tbody, .subtable th, .subtable td, .subtable tr { display: block; }
  .subtable thead tr { position: absolute;top: -9999px;left: -9999px; }

  .subtableid td,.subtableid th {
    display: block;
    padding: 1rem;
    text-align:left;
  }
  
  .subtable td {position: relative;padding-left: 40%;text-align:left; }
  .subtable td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}
  .subtable td:nth-of-type(1):before { content: ""; }
  .subtable td:nth-of-type(2):before { content: ""; }
  .subtable td:nth-of-type(3):before { content: ""; }
  .subtable td:nth-of-type(4):before { content: ""; }
  .descarto {display:none;}
  .fontsize {font-size:10px;}
}

@media only screen and (max-width: 1024px)  
{
  .btn-delete-mobile {
    visibility: visible !important;
  }

  .tdButton {
    display:none !important;
  }

  .tdTable {
    height: auto;
    padding-left: 0px;
  }

  .btn-delete-desktop {
    visibility: hidden;
  }

  .btn-load-mobile {
    visibility: visible;
  }

  .btn-load-document-desktop {
    visibility: hidden;
  }
}
</style>